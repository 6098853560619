import { TFunction } from 'i18next'
import _ from 'lodash'
import { createSelector } from 'reselect'

import {
  BEFORE_SHIFT_SIGN_IN_OPEN_HOURS,
  DISABLED_SIGN_IN_BUTTON_HOURS,
  TICKER_FALLBACK_DURATION_HOURS,
} from './constants'
import { findMostCritical } from './lib/findings'
import moment from './lib/moment-fi'
import { isRestDay } from './lib/shifts'
import { amendSmEquipments, getSm3TrainId, getSm6TrainId, isSm3, isSm6 } from './lib/smUtils'
import { getName } from './lib/stations'
import { formatDuration } from './lib/time'
import { DEFAULT_TASK_STATE } from './reducers/ShiftPage'
import { theme, unPx } from './Theme'
import {
  AppState,
  Assembly,
  AssemblyLeg,
  CauseGroup,
  Contact,
  ContactLocation,
  ContactState,
  CrewNoticeState,
  Event,
  Feedback,
  Finding,
  FindingsState,
  FirstLevelCause,
  MainPageState,
  MergedAssembly,
  MergedWagon,
  Moment,
  OperativeWagon,
  PunctualityState,
  SalesWagon,
  SchedulePart,
  SecondLevelCause,
  Shift,
  ShiftState,
  SignedIn,
  SignInStatus,
  Task,
  TaskContact,
  TaskContactRef,
  TaskDetailsInput,
  TaskEquipment,
  TaskFormation,
  TaskLeg,
  TaskState,
  Timestamp,
  TowingVehicle,
  VehicleStateChange,
} from './types'
import { ScheduleDay, ScheduleItem, ScheduleSeparator, ScheduleShiftList } from './types/App'
import {
  Causes,
  CrewNotice,
  Delay,
  DelayCause,
  FlattenedCauses,
  InputShift,
  RestDay,
  TimetableParams,
  TrainPunctuality,
} from './types/Input'

interface CrewNotices {
  byCrewNoticeId: Record<string, CrewNotice>
}

interface Shifts {
  byId: Record<string, Shift>
}

type NowSelector = (state: AppState) => Moment

// General functions
export function first<T>(collection: Array<T>): T | null {
  return collection ? collection[0] : null
}
export function last<T>(collection: Array<T>): T | null {
  return collection ? collection[collection.length - 1] : null
}

export function takeWhile<T>(pred: (t: T) => boolean, xs: Array<T>): Array<T> {
  if (!pred || !xs || !xs || xs.length === 0) {
    return []
  }

  let idx = 0

  while (idx < xs.length && pred(xs[idx])) {
    idx = idx + 1
  }

  return xs.slice(0, idx)
}

export function dropWhile<T>(pred: (t: T) => boolean, xs: Array<T>): Array<T> {
  if (!pred || !xs || !xs || xs.length === 0) {
    return []
  }

  let idx = 0

  while (idx < xs.length && pred(xs[idx])) {
    idx = idx + 1
  }

  return xs.slice(idx)
}

function id<T>(value: T): T {
  return value
}
function selector<T>(fn: (appState: AppState) => T): (appState: AppState) => T {
  return createSelector(fn, id) as (appState: AppState) => T
}

export const isPassed = (now: Moment, end: Timestamp): boolean => {
  return now.isAfter(moment(end))
}

export const happensToday = (now: Moment, value: Timestamp): boolean => {
  const start = now.clone().startOf('day')
  const end = now.clone().endOf('day')
  return moment(value).isBetween(start, end)
}

export const happensTomorrow = (now: Moment, value: Timestamp): boolean => {
  const start = now.clone().add(1, 'day').startOf('day')
  const end = now.clone().add(1, 'day').endOf('day')
  return moment(value).isBetween(start, end)
}

export const timeProgress = (
  now: Moment,
  targetDate: Timestamp,
  startingDate?: Timestamp | null
) => {
  const start = startingDate
    ? moment(startingDate)
    : moment(targetDate).subtract(TICKER_FALLBACK_DURATION_HOURS, 'hour')
  const end = moment(targetDate)
  const progress = now.diff(start) / end.diff(start)
  return Math.min(Math.max(0, progress), 1)
}

export const formatTimespan = (t: TFunction, now: Moment, target: Timestamp) => {
  const duration = moment(target).diff(now)
  return formatDuration(duration) || t('aMoment')
}

// Punctuality functions
export const getPunctualityKey = (
  trainNumber?: string | null,
  trainDate?: string | null
): string => {
  return `${trainNumber || ''}+${trainDate || ''}`
}

export const selectPunctuality = (
  punctualityState: PunctualityState,
  punctualityKey: string
): TrainPunctuality => {
  return punctualityState.punctualityByTrain[punctualityKey]
}

export const getCauses = (delays: Array<Delay>, maxLabels?: number): Array<DelayCause> => {
  let causeArray: Array<DelayCause> = []

  delays
    .filter((delay?: Delay) => delay?.causes?.length)
    .forEach((delay) => {
      delay?.causes?.forEach((cause) => {
        causeArray.push(cause)
      })
    })

  if (maxLabels && causeArray.length > maxLabels) {
    causeArray = causeArray.slice(0, maxLabels)
    causeArray.push({
      categoryCodeId: '-1',
      categoryCode: '...',
      categoryName: 'Out of space for labels',
    })
  }

  return causeArray
}

// Task functions

export const taskIsActive = (now: Moment, task: Task): boolean => {
  return now.isBetween(moment(task.taskStartDateTime), moment(task.taskEndDateTime))
}

export const taskIsPassed = (now: Moment, task: Task): boolean => {
  return isPassed(now, task.taskEndDateTime)
}

export const removeDupeContacts = (contacts: Array<TaskContactRef>): Array<TaskContactRef> => {
  let actionTaken = false
  const filteredContacts: TaskContactRef[] = []
  _.each(contacts, (c) => {
    //check if the person is already on the list
    //and check if the duplicate contact has the same role
    //and check if the start location matches arrival location in the duplicate contact or vice versa
    const i = _.findIndex(filteredContacts, { id: c.id })
    if (
      i >= 0 &&
      filteredContacts[i].role === c.role &&
      (filteredContacts[i].toStation === c.fromStation ||
        filteredContacts[i].fromStation === c.toStation)
    ) {
      //concatenate
      const newContact = { ...filteredContacts[i] }
      if (filteredContacts[i].toStation === c.fromStation) {
        newContact.toStation = c.toStation
      } else if (filteredContacts[i].fromStation === c.toStation) {
        newContact.fromStation = c.fromStation
      }
      actionTaken = true
      filteredContacts[i] = newContact
    } else {
      filteredContacts.push(c)
    }
  })
  //if action taken, call the function again
  //otherwise return array as-is
  return actionTaken ? removeDupeContacts(filteredContacts) : filteredContacts
}

export const selectLocationText = (t: TFunction, location?: ContactLocation): string => {
  if (!location || !location.type) {
    return ''
  }
  if (location.type === 'on-station') {
    return `${t('located.notOnTrain')}, ${t('located.onStation')} ${
      location.station ? location.station : ''
    }`
  } else if (location.type === 'on-vehicle') {
    // Figure out if the train is the correct one
    return `${t('located.onTrain')} ${location.line ? location.line : ''} ${
      location.trainNumber ? location.trainNumber : ''
    }, ${t(`located.vehicle`)} ${location.vehicle ? location.vehicle : ''}`
  }
  return ''
}

export const contactsForTask = (task: Task | null, userNumber: string): Array<TaskContactRef> => {
  return task && task.contacts ? task.contacts.filter((c) => c.id !== userNumber) : []
}

export const contactsSelector = selector((state: AppState): ContactState => state.contacts)

export const resolveTaskContactReferences = createSelector(
  contactsSelector,
  (contactState: ContactState) =>
    (contacts: Array<Partial<TaskContactRef>>): Array<Partial<TaskContact>> => {
      return contacts && contacts.length > 0
        ? contacts.map((c) => {
            return {
              ...c,
              ...(c.number && contactState.byId[c.number]),
              toStation: c.toStation,
              fromStation: c.fromStation,
            }
          })
        : []
    }
)

export const contactsForCommuterTask = (task: Task | null): Array<Contact> => {
  if (task && task.sections) {
    return task.sections.flatMap((section) => {
      return section.persons.map((person) => ({
        name: `${person.firstName} ${person.lastName}`,
        number: person.personnelNumber,
        email: person.emailAddress,
        telephone: person.workPhoneNumber,
        role: person.crewTypeShortName,
        fromStation: person.startLocation,
        toStation: person.endLocation,
      }))
    })
  }
  return []
}

export const getVehicleCount = (task: Task | null): number => {
  if (task && task.sections) {
    return task.sections.reduce(
      (acc, curr) => acc + ((curr.formations && curr.formations.length) || 0),
      0
    )
  }

  return 0
}

export const getVehicleNumbers = (task: Task | null): Array<string> => {
  if (task && task.sections) {
    const vehicleNumbers = task.sections.flatMap((section) => {
      return section.formations.map((formation) => formation.vehicleNumberShort)
    })
    return [...new Set(vehicleNumbers)].filter((vehicleNumber) => vehicleNumber !== undefined)
  }

  return []
}

export const taskInfoLocalized = (
  t: TFunction,
  task: Task
): Array<{
  title: string
  value: string
}> => {
  const locsWithPickUp = task.locomotives
    ? task.locomotives
        .filter((l) => l.pickUpLocation && l.locId)
        .map((l) => l.locId.concat(': ', l.pickUpLocation ? l.pickUpLocation : ''))
    : []
  const locsWithDropOff = task.locomotives
    ? task.locomotives
        .filter((l) => l.dropOffLocation && l.locId)
        .map((l) => l.locId.concat(': ', l.dropOffLocation ? l.dropOffLocation : ''))
    : []

  return [
    {
      title: t('fromTrain'),
      value: task.previousArrival ? task.previousArrival : '',
    },
    {
      title: t('startTrack'),
      value: task.departureTrack ? task.departureTrack : '',
    },
    {
      title: t('units'),
      value: task.traction ? task.traction : '',
    },
    {
      title: t('trainNumber'),
      value: task.trainNumber,
    },
    {
      title: t('lockingInformation'),
      value: task.lockingInformation ? task.lockingInformation : '',
    },
    {
      title: t('endTrack'),
      value: task.arrivalTrack ? task.arrivalTrack : '',
    },
    {
      title: t('toTrain'),
      value: task.nextDeparture ? task.nextDeparture : '',
    },
    {
      title: t('location1'),
      value: task.locomotives ? locsWithPickUp.join('\n') : '',
    },
    {
      title: t('location2'),
      value: task.locomotives ? locsWithDropOff.join('\n') : '',
    },
    {
      title: t('remarks'),
      value: task.locomotives
        ? task.locomotives
            .map((l) => l.remarks)
            .filter((r) => r !== undefined && r !== '')
            .join('\n')
        : '',
    },
  ]
}

export const commuterFromTrainDetails = (task: Task): TaskDetailsInput => {
  const formations: Array<TaskFormation> = task.sections
    ? task.sections
        .flatMap((section) => section.formations)
        .filter((f) => f.previousTrain && !!f.previousTrain.trainNumber)
    : []

  if (!formations || formations.length === 0) {
    return {
      isCommuter: true,
      type: 'from',
      trains: [],
      station: task.fromStation ? getName(task.fromStation) : '',
    }
  }

  let multipleSources = false
  for (let i = 1; i < formations.length; i++) {
    if (
      (formations[i].previousTrain && formations[i].previousTrain.trainNumber) !==
      (formations[i - 1].previousTrain && formations[i - 1].previousTrain.trainNumber)
    )
      multipleSources = true
  }

  const trains = multipleSources
    ? formations.map((f) => ({
        trainNumber: f.previousTrain && f.previousTrain.trainNumber,
        time: moment(f.previousTrain && f.previousTrain.scheduledArrivalTime).format('HH:mm'),
        frame: f.vehicleNumberShort,
        lineId: f.previousTrain && f.previousTrain.commuterLineID,
      }))
    : [
        {
          trainNumber: formations[0].previousTrain && formations[0].previousTrain.trainNumber,
          time: moment(
            formations[0].previousTrain && formations[0].previousTrain.scheduledArrivalTime
          ).format('HH:mm'),
          frame: formations[0].vehicleNumberShort,
          lineId: formations[0].previousTrain && formations[0].previousTrain.commuterLineID,
        },
      ]

  return {
    isCommuter: true,
    station: getName(task.fromStation),
    trains: trains.filter((tr) => tr.trainNumber !== undefined),
    type: 'from',
  }
}

export const commuterToTrainDetails = (task: Task): TaskDetailsInput => {
  const formations: Array<TaskFormation> = task.sections
    ? task.sections
        .flatMap((section) => section.formations)
        .filter((f) => f.nextTrain && !!f.nextTrain.trainNumber)
    : []
  if (!formations || formations.length === 0) {
    return {
      isCommuter: true,
      type: 'to',
      trains: [],
      station: task.toStation ? getName(task.toStation) : '',
    }
  }

  let multipleDestinations = false
  for (let i = 1; i < formations.length; i++) {
    if (
      (formations[i].nextTrain && formations[i].nextTrain.trainNumber) !==
      (formations[i - 1].nextTrain && formations[i - 1].nextTrain.trainNumber)
    )
      multipleDestinations = true
  }

  const trains = multipleDestinations
    ? formations.map((f) => ({
        trainNumber: f.nextTrain && f.nextTrain.trainNumber,
        time: moment(f.nextTrain && f.nextTrain.scheduledDepartureTime).format('HH:mm'),
        frame: f.vehicleNumberShort,
        lineId: f.nextTrain && f.nextTrain.commuterLineID,
      }))
    : [
        {
          trainNumber: formations[0].nextTrain && formations[0].nextTrain.trainNumber,
          time: moment(
            formations[0].nextTrain && formations[0].nextTrain.scheduledDepartureTime
          ).format('HH:mm'),
          frame: formations[0].vehicleNumberShort,
          lineId: formations[0].nextTrain && formations[0].nextTrain.commuterLineID,
        },
      ]

  return {
    isCommuter: true,
    station: getName(task.toStation),
    trains: trains.filter((tr) => tr.trainNumber !== undefined),
    type: 'to',
  }
}

//Number contained in "trainNumber" field is train numeric number only if it is:
//  1. Consisting of digits
//  2. (Optionally) prefixed with 'a' or 'm'
//  3. (Optionally) suffixed with 'X', 'Y' or 'Z'
export const selectNumericTrainNumber = (trainNumber: string): string => {
  return trainNumber && /^[am]?\d*[XYZ]?$/.test(trainNumber) ? trainNumber.replace(/\D/g, '') : ''
}

export const selectTaskInstruction = (
  trainNumber: string,
  trainNumberNumeric: string,
  instruction: string
): string => {
  return (
    (instruction ? instruction : '') +
    (trainNumberNumeric === '' && trainNumber.length > 8 ? ' ' + trainNumber : '')
  )
}

export const taskState = (state: AppState, shiftId: string, taskIndex: number): TaskState => {
  const shiftState = state.shiftPage.taskState[shiftId] || {}
  return shiftState[taskIndex] || DEFAULT_TASK_STATE
}

export const containsRelevantLocation = (
  state: AppState,
  contact: Contact,
  shiftId: string,
  taskIndex: number
): boolean => {
  const task: Task | null = state.shifts?.byId?.[shiftId]?.tasks?.[taskIndex] ?? null
  return Boolean(
    contact.location &&
      contact.location.timestamp &&
      moment(contact.location.timestamp).isAfter(
        moment(task && task.taskStartDateTime).add(-15, 'minutes')
      ) &&
      moment(contact.location.timestamp).isBefore(
        moment(task && task.taskEndDateTime).add(15, 'minutes')
      )
  )
}

export const selectTaskTitle = (
  taskName: string,
  trainNumber: string,
  trainNumberNumeric: string,
  trainCategory?: string,
  short = false
): string => {
  const condTask: boolean = ['kond', 'akond', 'akondp', 'mw'].some((s) => s === taskName)
  if (short && condTask) {
    switch (taskName) {
      case 'kond':
        taskName = 'k.'
        break
      case 'akond':
        taskName = 'ak.'
        break
      case 'akondp':
        taskName = 'akp.'
        break
      default:
        break
    }
  }

  const waiterTask: boolean = ['JT', 'JTa', 'JTk'].some((s) => s === taskName)
  if (short && waiterTask) {
    switch (taskName) {
      // add special logic for mapping waiter task names
      default:
        break
    }
  }

  const fullTaskNames: Array<string> = ['päiv', 'päiv.ro', 'päiv.t.t.']

  let taskTitle: string = taskName
  if (taskName === 'm') {
    taskTitle = 'm' + trainNumberNumeric
  } else if (taskName && trainNumberNumeric && condTask && !taskName.includes(trainNumberNumeric)) {
    taskTitle = taskName + ' ' + (trainCategory ? trainCategory : '') + trainNumberNumeric
  } else if (taskName && trainNumber && waiterTask) {
    taskTitle = taskName + ' ' + (trainCategory ? trainCategory : '') + trainNumber
  }
  if (
    taskName &&
    trainNumberNumeric === '' &&
    fullTaskNames.some((s) => s === taskName.toLowerCase())
  ) {
    taskTitle = taskTitle + ' ' + trainNumber
  }
  return taskTitle
}

export const mergeTasksWithNotices = (
  tasks: Array<Task>,
  notices: Array<CrewNotice>
): Array<Task> => {
  if (!notices) {
    return tasks
  }
  const mergedTasks = [...tasks]
  notices.forEach((n) => {
    if (n) {
      const taskIndex = mergedTasks.findIndex(
        (task) =>
          n && n.trains && n.trains.some((train) => train.trainNumber === task.trainNumberNumeric)
      )

      if (taskIndex > -1) {
        const task = mergedTasks[taskIndex]
        if (moment(n.occursAt).isBefore(moment(task.taskEndDateTime).add(-5, 'minutes'))) {
          mergedTasks[taskIndex] = {
            ...task,
            leadingNotices: task.leadingNotices ? task.leadingNotices.concat(n) : [n],
          }
        } else {
          mergedTasks[taskIndex] = {
            ...task,
            trailingNotices: task.trailingNotices ? task.trailingNotices.concat(n) : [n],
          }
        }
      } else {
        const tasksBeforeOccurs = mergedTasks.filter((t) => {
          return moment(n.occursAt).isAfter(moment(t.taskEndDateTime))
        }).length
        const task =
          mergedTasks[
            tasksBeforeOccurs < mergedTasks.length ? tasksBeforeOccurs : tasksBeforeOccurs - 1
          ]
        mergedTasks[
          tasksBeforeOccurs < mergedTasks.length ? tasksBeforeOccurs : tasksBeforeOccurs - 1
        ] = {
          ...task,
          independentNotices: task.independentNotices ? task.independentNotices.concat(n) : [n],
        }
      }
    }
  })

  return mergedTasks
}

export const selectOperatingDateForTask = (task: Task): string => {
  return task.operatingDate
    ? task.operatingDate
    : moment(task.taskStartDateTime).format('YYYY-MM-DD')
}

// Shift functions

export const shiftHasInvalidEnding = (shift: Shift | InputShift | RestDay): boolean =>
  shift.endDateTime < shift.startDateTime

export const shiftIsActive = (now: Moment, shift: Shift): boolean => {
  if (shiftHasInvalidEnding(shift)) {
    return now.isAfter(moment(shift.startDateTime))
  }
  return now.isBetween(moment(shift.startDateTime), moment(shift.endDateTime))
}
export const shiftIsPassed = (now: Moment, shift: Shift | RestDay): boolean => {
  if (shiftHasInvalidEnding(shift)) {
    return false
  }
  return isPassed(now, ('scheduleEndTime' in shift && shift.scheduleEndTime) || shift.endDateTime)
}
export const shiftFirstTask = (shift: Shift): Task | null => first(shift.tasks ? shift.tasks : [])
export const shiftLastTask = (shift: Shift): Task | null => last(shift.tasks ? shift.tasks : [])

export const shiftIsOver48hAway = (now: Moment, shift: Shift): boolean => {
  return Math.abs(now.diff(shift.startDateTime, 'hours')) > 48
}

export const showSignIn = (now: Moment, shift: Shift): boolean => {
  const startTime = shift.scheduleStartTime || shift.startDateTime
  return moment(startTime).diff(now, 'hours') < DISABLED_SIGN_IN_BUTTON_HOURS
}

export const shiftCanSignIn = (
  now: Moment,
  shift: Shift,
  status: SignInStatus,
  optionalStartTime?: Moment
): boolean => {
  const state: SignedIn = status.state
  if (shift.isCommuter) {
    return false
  }
  if (state === 'signed-in') {
    return false
  }
  if (shiftIsPassed(now, shift)) {
    return false
  }
  return (
    state === 'open' &&
    now.isAfter(
      moment(optionalStartTime || shift.startDateTime).subtract(
        BEFORE_SHIFT_SIGN_IN_OPEN_HOURS,
        'hours'
      )
    )
  )
}

export const shiftSignInStatus = createSelector(
  (state: AppState): Record<string, SignInStatus> => state.signInStatuses.byId,
  (byId: Record<string, SignInStatus>) =>
    _.memoize(
      (id: string): SignInStatus => byId[id] || { id, state: 'open', loading: false, error: '' }
    )
)

export const shiftFeedback = (shiftId: string, state: AppState): Feedback | null => {
  const id = state.feedbacks.idsByShiftId[shiftId]
  if (id) {
    return state.feedbacks.byId[id]
  }
  return state.feedbacks.workspace[`${shiftId}:shift`]
}

export const shiftListFeedback = (listId: string, state: AppState): Feedback | null => {
  const id = state.feedbacks.idsByListId[listId]
  if (id) {
    return state.feedbacks.byId[id]
  }
  return state.feedbacks.workspace[`${listId}:schedule`]
}

export const shiftTasksToTimetableParams = (shift: Shift): Array<TimetableParams> => {
  const params = (shift.tasks ? shift.tasks : [])
    .filter((t) => !!t.trainNumberNumeric && (t.fromStation !== t.toStation || shift.isCommuter))
    //filter out tasks where the employee is only a passenger
    .filter((t) => t.taskName !== 'm' && !t.trainNumber.startsWith('m'))
    .map((t) => ({
      trainNumber: t.trainNumberNumeric,
      timetableDate: moment(t.taskStartDateTime).format('YYYY-MM-DD'),
      depStation: t.fromStation,
      depTime: moment(t.taskStartDateTime).format('YYYY-MM-DDTHH:mm:ss'),
      arrStation: t.toStation,
      arrTime: moment(t.taskEndDateTime).format('YYYY-MM-DDTHH:mm:ss'),
    }))

  const parts: Array<TimetableParams> = []
  params.forEach((p) => {
    const previous = last(parts) // fetch previous
    if (
      previous &&
      previous.trainNumber === p.trainNumber &&
      previous.arrStation === p.depStation
    ) {
      const newPart = Object.assign(previous, { arrStation: p.arrStation, arrTime: p.arrTime })
      parts.pop() // remove previous
      parts.push(newPart)
    } else {
      parts.push(p)
    }
  })
  return parts
}

// Selectors
export const nowSelector: NowSelector = selector((state: AppState): Moment => {
  let now: Moment = moment(state.system.now)
  if (state.system.virtualTime.enabledAt > 0) {
    now = now.add(state.system.virtualTime.offset, 'milliseconds')
  }
  return now
}) as unknown as NowSelector

export const nightModeSelector = selector((state: AppState): boolean => state.system.nightMode)
export const shortNumberSelector = selector((state: AppState): boolean => state.system.shortNumbers)
export const shiftsSelector = selector((state: AppState): ShiftState => state.shifts)
export const restDaySelector = selector((state: AppState): RestDay[] => state.shifts.restDays)
export const mainPageSelector = selector((state: AppState): MainPageState => state.mainPage)
export const isMobileSelector = selector(
  (state: AppState): boolean => state.system.screenWidth <= unPx(theme.breakpoints.large)
)
export const isPhoneSelector = selector(
  (state: AppState): boolean => state.system.screenWidth <= unPx(theme.breakpoints.medium)
)
export const findingsSelector = selector((state: AppState): FindingsState => state.findings)
export const crewNoticeSelector = selector((state: AppState): CrewNoticeState => state.crewNotices)

export const crewNoticeTargetShiftSelector = createSelector(
  shiftsSelector,
  crewNoticeSelector,
  (shifts: Shifts, crewNotices: CrewNotices) => (crewNoticeId: string) => {
    const cn: CrewNotice = crewNotices.byCrewNoticeId[crewNoticeId]
    const shiftArray: Array<Shift> = Object.values(shifts.byId)
    const targetShift = shiftArray.find((s) => {
      // moment defaults to current time when initializing with undefined values
      if (!(s.startDateTime && s.endDateTime)) {
        return false
      }
      return (
        moment(s.startDateTime).add(-1, 'hours').isBefore(moment(cn.occursAt)) &&
        moment(s.endDateTime).add(1, 'hours').isAfter(moment(cn.occursAt))
      )
    })
    return targetShift && targetShift.id
  }
)

export const shiftByIdSelector = createSelector(
  shiftsSelector,
  (shifts: Shifts) => (shiftId: string) => {
    const shiftArray: Array<Shift> = Object.values(shifts.byId)
    const matchedShift = shiftArray.find((s) => s.shiftId === shiftId)
    return matchedShift?.id
  }
)

export const uniqueLocomotiveIds = (task: Task | null): Array<string> => {
  if (!task) return []
  return _.uniq(task.locomotives ? task.locomotives.map((loc) => loc.locId) : [])
}

export const inferTaskEquipment = (task: Task | null): Array<string> => {
  return amendSmEquipments(uniqueLocomotiveIds(task))
}

// For Sm3 and Sm6 equipments only 2 last digits of the locid should be shown in the task
export const inferTaskEquipmentDisplay = (task: Task | null): Array<string> => {
  const equipments = uniqueLocomotiveIds(task)
  return equipments.map((equipmentId) => {
    if (isSm3(equipmentId)) {
      return getSm3TrainId(equipmentId)
    }
    if (isSm6(equipmentId)) {
      return getSm6TrainId(equipmentId)
    }
    return equipmentId
  })
}

export const filterEquipments = (equipments: Array<string>): Array<string> => {
  return equipments
    .filter((e) => typeof e === 'string')
    .map((e) => e.replace(/[^\d].*/, ''))
    .filter((e) => e.match(/^[0-9]+$/))
}

// How long the findings are kept, 90 seconds
export const FINDINGS_TTL_MS = 90 * 1000

export const hasExpiredFindings = (
  findingsState: FindingsState,
  locIds: Array<string>
): boolean => {
  const now = new Date().getTime()
  return locIds.some(
    (locId: string): boolean =>
      !findingsState?.findingsByLocId?.[locId] ||
      findingsState.findingsByLocId[locId].timestamp + FINDINGS_TTL_MS < now
  )
}

// Return valid (as in not expired) findings for a list of equimepnts
export const selectEquipmentFindings = (
  state: FindingsState,
  uniqueLocomotives: Array<string>
): TaskEquipment | null => {
  if (!uniqueLocomotives) {
    return {
      loading: false,
      error: '',
      findings: [],
      highestCriticality: null,
    }
  }

  const loadingEquipmentIndex = (locId: string): number =>
    state.equipmentLoading?.indexOf(locId) ?? -1

  const loading = uniqueLocomotives.some((locId: string) => loadingEquipmentIndex(locId) >= 0)

  const error: string | null =
    _.first(
      uniqueLocomotives.reduce((result: Array<string>, locId: string): Array<string> => {
        const errors = state.equipmentErrors?.[locId]
        return errors ? [...result, errors] : result
      }, [])
    ) ?? null

  const findings = uniqueLocomotives.reduce(
    (result: Array<Finding>, locId: string): Array<Finding> => {
      if (locId) {
        const findingEntry = state.findingsByLocId?.[locId]
        return findingEntry ? [...result, ...findingEntry.findings] : result
      }

      return result
    },
    []
  )

  return {
    loading,
    error,
    findings,
    highestCriticality: findMostCritical(findings),
  }
}

/** Memoized wrapper for selectEquipmentFindings */
export const equpmentsFindingsSelector = createSelector(
  (state: AppState) => state.findings,
  (_: AppState, locIds?: string[]) => locIds,
  selectEquipmentFindings
)

// Return equipment findings for the task, only those that haven't expired
export const selectTaskEquipment = (
  state: FindingsState,
  currentTask: Task | null
): TaskEquipment | null => {
  if (!currentTask) return null
  const uniqueLocomotives: Array<string> = inferTaskEquipment(currentTask)
  return selectEquipmentFindings(state, uniqueLocomotives)
}

export const shiftsSortedByDateSelector = createSelector(
  nowSelector,
  shiftsSelector,
  (now, shifts: Shifts) => _.sortBy(_.values(shifts.byId), 'startDateTime')
)

export const shiftsAndRestDaysSortedByDateSelector = createSelector(
  nowSelector,
  shiftsSelector,
  restDaySelector,
  (now, shifts: Shifts, restDays: RestDay[]) =>
    _.sortBy([..._.values(shifts.byId), ...restDays], 'startDateTime')
)

const shiftIsToday = (now: Moment, shift: Shift): boolean =>
  happensToday(now, shift.startDateTime) ||
  happensToday(now, shift.endDateTime) ||
  shiftIsActive(now, shift)

export const shiftsInNextDaysSelector = createSelector(
  nowSelector,
  shiftsSortedByDateSelector,
  (now, shifts): Array<Array<Shift>> => [
    shifts.filter((shift) => shiftIsToday(now, shift)),
    shifts.filter((shift) => happensTomorrow(now, shift.startDateTime)),
  ]
)

export const nextShiftSelector = createSelector(
  nowSelector,
  shiftsSortedByDateSelector,
  (now: Moment, shifts: Array<Shift>): Shift | null =>
    shifts.find((shift) => !shiftIsPassed(now, shift)) || null
)

const shiftOrRestDayStartMoment = (shiftOrRestDay: Shift | RestDay): Moment =>
  moment(
    (!isRestDay(shiftOrRestDay) && shiftOrRestDay.scheduleStartTime) || shiftOrRestDay.startDateTime
  )

const shiftListIdsSelector = createSelector(
  shiftsAndRestDaysSortedByDateSelector,
  (shiftsAndRestDays): Array<string | undefined> => {
    const shiftIds = new Set<string | undefined>()
    shiftsAndRestDays.forEach((shiftOrRestDay) => shiftIds.add(shiftOrRestDay.listId))

    return Array.from(shiftIds.values())
  }
)

/**
 * Selects shifts and rest days grouped by day, all grouped by containing shift list.
 * Everything is in chronological order (by start time).
 * Includes all empty days between the first and last shift or rest day.
 * Will generate empty days that do not belong into a shift list to preserve continuity.
 */
export const shiftListsWithShiftsAndRestDaysSelector = createSelector(
  shiftsAndRestDaysSortedByDateSelector,
  shiftListIdsSelector,
  (
    shiftsAndRestDays,
    shiftListIds
  ): {
    listId?: string
    listStartDate: string
    listEndDate: string
    days: { startDateTime: string; shiftsAndRestDays: Array<Shift | RestDay> }[]
  }[] => {
    if (!shiftsAndRestDays.length) return []
    const groupedByShiftList = shiftListIds.map((listId) => {
      const containedShiftsAndRestDays = shiftsAndRestDays.filter(
        (shiftOrRestDay) => shiftOrRestDay.listId === listId
      )

      const listStartDate = containedShiftsAndRestDays[0].listStartDate
      const listEndDate =
        containedShiftsAndRestDays[0].listEndDate ||
        // since commuter shift lists do not have an end date:
        shiftOrRestDayStartMoment(
          containedShiftsAndRestDays[containedShiftsAndRestDays.length - 1]
        ).format('YYYY-MM-DD')

      return {
        listId,
        listStartDate,
        listEndDate,
        shiftsAndRestDays: containedShiftsAndRestDays,
      }
    })

    const groupedByShiftListAndDay = groupedByShiftList.map(
      ({ shiftsAndRestDays, ...shiftList }, shiftIdx) => {
        const firstDay = (
          shiftIdx === 0
            ? // do not show empty days at the start of the first shift list
              shiftOrRestDayStartMoment(shiftsAndRestDays[0])
            : // for continuity, show the empty days at the start of subsequent shift lists
              moment(shiftList.listStartDate)
        ).startOf('day')

        const lastDay = (
          shiftIdx + 1 < groupedByShiftList.length
            ? // show empty days at the end of the shift list, if there is another list afterwards
              moment(groupedByShiftList[shiftIdx + 1].listStartDate).subtract(1, 'day')
            : // do not show final "dangling" empty days of the last shift list
              shiftOrRestDayStartMoment(shiftsAndRestDays[shiftsAndRestDays.length - 1])
        ).startOf('day')

        const numDays = 1 + lastDay.diff(firstDay, 'day')
        const days = Array.from(Array(numDays), (_v, i) => ({
          startDateTime: firstDay.clone().add(i, 'day').toISOString(true),
          shiftsAndRestDays: new Array<Shift | RestDay>(),
        }))

        shiftsAndRestDays.forEach((shiftOrRestDay) => {
          const dayMoment = shiftOrRestDayStartMoment(shiftOrRestDay).startOf('day')
          const dayNumber = dayMoment.diff(firstDay, 'day')
          days[dayNumber].shiftsAndRestDays.push(shiftOrRestDay)
        })

        return {
          ...shiftList,
          days,
        }
      }
    )

    return groupedByShiftListAndDay
  }
)

const shiftOrRestdayToScheduleParts = (shiftOrRestDay: Shift | RestDay): SchedulePart[] => {
  if (isRestDay(shiftOrRestDay)) {
    return [
      {
        type: 'restday',
        dayType: shiftOrRestDay.dayType,
        startDateTime: shiftOrRestDay.startDateTime,
        endDateTime: shiftOrRestDay.endDateTime,
      },
    ]
  } else {
    if (shiftOrRestDay.rests?.length > 0) {
      const lastTask = shiftLastTask(shiftOrRestDay)
      return [
        {
          type: 'shift',
          shift: shiftOrRestDay,
        },
        {
          type: 'rest',
          startDateTime: shiftOrRestDay.rests[0].startTimestamp,
          endDateTime: shiftOrRestDay.rests[0].endTimestamp,
          location: lastTask?.toStation ?? '',
        },
      ]
    } else {
      return [
        {
          type: 'shift',
          shift: shiftOrRestDay,
        },
      ]
    }
  }
}

const createScheduleMonthWeekSeparators = (
  previousStart: Moment,
  currentStart: Moment
): Array<ScheduleSeparator> => {
  const result: Array<ScheduleSeparator> = []

  if (!currentStart.isSame(previousStart, 'month')) {
    result.push({
      type: 'month',
      startDateTime: currentStart.clone().startOf('month').toISOString(true),
    })
  }

  if (!currentStart.isSame(previousStart, 'week')) {
    result.push({
      type: 'week',
      startDateTime: currentStart.clone().startOf('week').toISOString(true),
    })
  }

  return result
}

const insertLineIntoTodaysScheduleParts = (scheduleParts: SchedulePart[], now: Moment): void => {
  // It would be a lot simpler to just use findLastIndex, but the dev environment is using
  // an old version of node that does not support it. This means that even if it works in
  // browser, it does not work in tests.
  // When(/if) node is updated to version 18+, should be changed to:
  // ...idx = scheduleParts.findLastIndex((part...
  const idx =
    scheduleParts.length -
    1 -
    scheduleParts
      .slice()
      .reverse()
      .findIndex((part) => {
        switch (part.type) {
          case 'shift':
            return now.isBefore(part.shift.scheduleEndTime || part.shift.endDateTime)
          case 'restday':
            return now.isBefore(part.endDateTime)
          case 'rest':
            // Since conductors' rests are displayed after the shift even if they occur during it
            // Drivers' shifts are partitioned to account for this in eSälli
            return false
          default:
            return false
        }
      })

  if (idx === -1) {
    scheduleParts.push({ type: 'line' })
  } else {
    scheduleParts.splice(idx, 0, { type: 'line' })
  }
}

const insertLineIntoStartOrEndOfShiftLists = (
  shiftLists: ScheduleShiftList[],
  now: Moment
): void => {
  const firstScheduleItem = shiftLists[0].schedule[0]
  if (firstScheduleItem.type === 'line') {
    return
  }

  if (now.isBefore(firstScheduleItem.startDateTime)) {
    shiftLists[0].schedule.splice(0, 0, { type: 'line' })
    return
  }

  const lastSchedule = shiftLists[shiftLists.length - 1].schedule
  const lastScheduleItem = lastSchedule[lastSchedule.length - 1]
  if (lastScheduleItem.type === 'line') {
    return
  }

  if (now.isAfter(lastScheduleItem.startDateTime)) {
    lastSchedule.push({ type: 'line' })
  }
}

/**
 * Selects schedule parts grouped by day, all grouped by containing shift list.
 * Everything is in chronological order (by start time).
 * Includes all empty days between the first and last shift or rest day.
 */
export const schedulePartsGroupedSelector = createSelector(
  shiftListsWithShiftsAndRestDaysSelector,
  nowSelector,
  (shiftLists, now): ScheduleShiftList[] => {
    if (!shiftLists.length) return []

    let lineInserted = false

    const shiftListsParsed = shiftLists.map(({ days, ...shiftList }) => {
      const schedule = days.map<ScheduleDay>(({ startDateTime, shiftsAndRestDays }) => {
        const day: ScheduleDay = {
          type: 'day',
          startDateTime,
          scheduleParts: shiftsAndRestDays.flatMap((shiftOrRestDay) =>
            shiftOrRestdayToScheduleParts(shiftOrRestDay)
          ),
        }

        if (now.isSame(startDateTime, 'day')) {
          insertLineIntoTodaysScheduleParts(day.scheduleParts, now)
          lineInserted = true
        }

        return day
      })

      // add week and month separators
      const scheduleDecorated = schedule.flatMap<ScheduleItem>((currentDay, dayIdx) => {
        const currentDate = moment(currentDay.startDateTime)
        const previousDate =
          dayIdx === 0
            ? currentDate.clone().subtract(1, 'day')
            : moment(schedule[dayIdx - 1].startDateTime)

        return [...createScheduleMonthWeekSeparators(previousDate, currentDate), currentDay]
      })

      return { ...shiftList, schedule: scheduleDecorated }
    })

    // if line (current time indicator) is outside of displayed days
    if (!lineInserted) {
      insertLineIntoStartOrEndOfShiftLists(shiftListsParsed, now)
    }

    return shiftListsParsed
  }
)

const feedbacksSelector = selector((appState: AppState): Array<Feedback> => {
  return _.values(appState.feedbacks.byId)
})

const respondedFeedbacksSelector = createSelector(
  feedbacksSelector,
  (feedbacks: Array<Feedback>): Array<Feedback> => {
    return _.sortBy(
      feedbacks.filter((f) => !!f.respondedAt),
      'respondedAt'
    ).reverse()
  }
)

export const scheduleEventsSelector = createSelector(
  nowSelector,
  respondedFeedbacksSelector,
  (state: AppState): Record<string, boolean> => state.responseReads.byId,
  (state: AppState): Record<string, CrewNotice> => state.crewNotices.byCrewNoticeId,
  (
    now: Moment,
    feedbacks: Array<Feedback>,
    byId: Record<string, boolean>,
    byCrewNoticeId: Record<string, CrewNotice>
  ): Array<Event> => {
    const feedbackEvents = feedbacks.map((feedback: Feedback): Event => {
      const readStatus: boolean | null = byId[feedback.id]
      const read = readStatus || false

      return {
        id: feedback.id || '',
        date: feedback.respondedAt ? feedback.respondedAt : now.toISOString(),
        type: 'feedbackResponse',
        message: feedback.response,
        read,
        markAsReadText: 'markAsRead',
      }
    })
    const crewNoticeEvents = Object.keys(byCrewNoticeId).map((id: string): Event => {
      const crewNotice = byCrewNoticeId[id]
      const read = crewNotice.ack === 'ACKNOWLEDGED'
      return {
        id,
        date: crewNotice.sentAt,
        type: read ? 'readCrewNotice' : 'pendingCrewNotice',
        message: crewNotice.content,
        read,
        markAsReadText: crewNotice.isMassNotice ? 'closeNotification' : 'acknowledge',
        data: crewNotice,
      }
    })
    return [...feedbackEvents, ...crewNoticeEvents]
  }
)

// Deviation causes and amendments selectors

export const CAUSE_GROUP_LEVEL = 0
export const CAUSE_FIRST_LEVEL = 1
export const CAUSE_SECOND_LEVEL = 2

export const flattenCauses = (causes: Causes): FlattenedCauses => {
  const result: FlattenedCauses = {}
  Object.keys(causes).forEach((causeGroupKey: string) => {
    const causeGroup: CauseGroup = causes[causeGroupKey]
    if (!causeGroup) return
    Object.keys(causeGroup.firstLevelCodes).forEach((firstLevelKey) => {
      const firstLevelCause: FirstLevelCause = causeGroup.firstLevelCodes[firstLevelKey]
      if (!firstLevelCause) return
      if (firstLevelCause.secondLevelCodes) {
        Object.keys(firstLevelCause.secondLevelCodes).forEach((secondLevelKey) => {
          const secondLevelCause: SecondLevelCause =
            firstLevelCause.secondLevelCodes[secondLevelKey]
          if (!secondLevelCause) return
          result[secondLevelKey] = {
            description: secondLevelCause.description,
            responsible: secondLevelCause.responsible,
            code: secondLevelKey,
            parent: firstLevelKey,
            level: 2,
            causeGroupKey,
            hasSubCodes: false,
            askRelatedTrainNumber: secondLevelCause.askRelatedTrainNumber,
          }
        })
      }
      result[firstLevelKey] = {
        code: firstLevelKey,
        description: firstLevelCause.description,
        parent: causeGroupKey,
        level: 1,
        responsible: null,
        causeGroupKey,
        hasSubCodes: Boolean(firstLevelCause.secondLevelCodes),
      }
    })
    result[causeGroupKey] = {
      code: causeGroupKey,
      amendable: causeGroup.amendable,
      description: causeGroup.description,
      level: 0,
      parent: null,
      responsible: null,
      causeGroupKey,
      hasSubCodes: true,
    }
  })
  return result
}

export const currentVehicleStateSelector = (
  towingVehicleState: TowingVehicle
): VehicleStateChange | null => {
  const vehicleStates = (towingVehicleState && towingVehicleState.vehicleStates) || []
  return (
    vehicleStates.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))).find(Boolean) ||
    null
  )
}

export const mergeWagonLists = (
  salesWagons: Array<SalesWagon> = [],
  operativeWagons: Array<OperativeWagon> = [],
  mergedWagons: Array<MergedWagon>
): Array<MergedWagon> => {
  if (!salesWagons || salesWagons.length === 0) {
    return mergedWagons.concat(operativeWagons.map((w) => ({ operativeWagon: w })))
  }
  if (!operativeWagons || operativeWagons.length === 0) {
    return mergedWagons.concat(salesWagons.map((w) => ({ salesWagon: w })))
  }

  const salesWagon = first(salesWagons)
  const operativeWagon = first(operativeWagons)

  if (
    (salesWagon && salesWagon.bookingNumber) === (operativeWagon && operativeWagon.bookingNumber)
  ) {
    const wagon = {
      salesWagon,
      operativeWagon,
    }
    return mergeWagonLists(
      salesWagons.slice(1),
      operativeWagons.slice(1),
      mergedWagons.concat(wagon)
    )
  }

  // TODO: handle reordering where the corresponding wagons are already in the merged list

  // O(n*k) where k is the length of new/deleted wagons
  // Using a set would speed this up to O(k)
  // TODO: check if order is preserved with set

  /* deleted wagons */
  const salesWagonsNotInOperativeWagons = takeWhile(
    (w) =>
      !operativeWagons.some((operativeWagon) => w.bookingNumber === operativeWagon.bookingNumber) &&
      !mergedWagons.some(
        (mergedWagon) => mergedWagon?.operativeWagon?.bookingNumber === w.bookingNumber
      ),
    salesWagons
  ).map((w) => ({ salesWagon: w }))

  /* new wagons */
  const operativeWagonsNotInSalesWagons = takeWhile(
    (w) =>
      !salesWagons.some((salesWagon) => w.bookingNumber === salesWagon.bookingNumber) &&
      !mergedWagons.some(
        (mergedWagon) => mergedWagon?.salesWagon?.bookingNumber === w.bookingNumber
      ),
    operativeWagons
  ).map((w) => ({ operativeWagon: w }))

  if (
    salesWagonsNotInOperativeWagons.length !== 0 ||
    operativeWagonsNotInSalesWagons.length !== 0
  ) {
    return mergeWagonLists(
      salesWagons.slice(salesWagonsNotInOperativeWagons.length),
      operativeWagons.slice(operativeWagonsNotInSalesWagons.length),
      mergedWagons.concat(salesWagonsNotInOperativeWagons).concat(operativeWagonsNotInSalesWagons)
    )
  }

  /* changed ordering of wagons */
  let i = 0
  let commonReorderedBlock: Array<MergedWagon> = []

  while (
    salesWagons.length > i &&
    operativeWagons.length > i &&
    salesWagons[i].bookingNumber !== operativeWagons[i].bookingNumber &&
    /* eslint-disable no-loop-func */
    (salesWagons.some((w) => w.bookingNumber === operativeWagons[i].bookingNumber) ||
      mergedWagons.some(
        (w) => w?.salesWagon?.bookingNumber === operativeWagons[i].bookingNumber
      )) &&
    (operativeWagons.some((w) => w.bookingNumber === salesWagons[i].bookingNumber) ||
      mergedWagons.some((w) => w?.operativeWagon?.bookingNumber === salesWagons[i].bookingNumber))
    /* eslint-enable no-loop-func */
  ) {
    commonReorderedBlock = commonReorderedBlock.concat({
      salesWagon: salesWagons[i],
      operativeWagon: operativeWagons[i],
    })
    i = i + 1
  }

  return mergeWagonLists(
    salesWagons.slice(i),
    operativeWagons.slice(i),
    mergedWagons.concat(commonReorderedBlock)
  )
}

export const selectTaskAssembly = (
  assembliesById: Record<string, Assembly>,
  task: Task
): MergedAssembly | null => {
  const key = `${task.trainNumber}-${moment(task.taskStartDateTime).format('YYYY-MM-DD')}`
  const taskAssembly = assembliesById[key]
  if (!taskAssembly) {
    return null
  }
  // TODO: do wagons need to be sorted here to be sure?
  const legs = taskAssembly.legs

  const overlappingLegs = legs.filter((leg) => {
    return (
      (!moment(leg.departure).isAfter(moment(task.taskEndDateTime)) &&
        !moment(leg.departure).isBefore(moment(task.taskStartDateTime))) ||
      (!moment(leg.arrival).isBefore(moment(task.taskStartDateTime)) &&
        !moment(leg.arrival).isAfter(moment(task.taskEndDateTime))) ||
      (!moment(leg.departure).isAfter(moment(task.taskStartDateTime)) &&
        !moment(leg.arrival).isBefore(moment(task.taskEndDateTime)))
    )
  })

  const mapAssemblyLegToTaskLeg = (leg: AssemblyLeg): TaskLeg => ({
    ...leg,
    wagons: mergeWagonLists(leg.salesWagons, leg.operativeWagons, []),
  })

  return {
    id: taskAssembly.id,
    timestamp: taskAssembly.timestamp,
    trainNumber: taskAssembly.trainNumber,
    operatingDay: taskAssembly.operatingDay,
    legs: overlappingLegs.map(mapAssemblyLegToTaskLeg),
  }
}

export const selectLatestAssemblyChangeTimestamp = (legs?: Array<TaskLeg>) => {
  if (!legs) {
    return null
  }

  return legs.sort((a, b) => moment(b.lastUpdated).diff(moment(a.lastUpdated))).find(Boolean)
}
