import {
  ENERGY_EFFICIENCY_ERROR,
  START_FETCHING_ENERGY_EFFICIENCY,
  UPDATE_ENERGY_EFFICIENCY,
} from '../actions/energyEfficiency'
import { EnergyEfficiencyAction } from '../actions/energyEfficiency'
import { EnergyEfficiencyState } from '../types'

export const initialState: EnergyEfficiencyState = {
  loading: false,
  error: '',
}

const energyEfficiencyReducer = (
  state: EnergyEfficiencyState = initialState,
  action: EnergyEfficiencyAction
): EnergyEfficiencyState => {
  switch (action.type) {
    case START_FETCHING_ENERGY_EFFICIENCY:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_ENERGY_EFFICIENCY: {
      return {
        ...state,
        error: '',
        loading: false,
        energyEfficiency: action.payload,
      }
    }

    case ENERGY_EFFICIENCY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default energyEfficiencyReducer
