import { DurationInputArg1 } from 'moment-timezone'

export const S_IN_HOUR: number = 60 * 60
export const MS_IN_HOUR: number = S_IN_HOUR * 1000

const LONG_REST_THRESHOLD_HOURS = 2
export const LONG_REST_THRESHOLD_MS: number = LONG_REST_THRESHOLD_HOURS * MS_IN_HOUR

export const PAGE_ANIMATION_TIME_MS = 300
export const TICKER_FALLBACK_DURATION_HOURS = 2
export const BEFORE_SHIFT_SIGN_IN_OPEN_HOURS = 1
export const STARTS_SOON_HOURS = 2

export const DISABLED_SIGN_IN_BUTTON_HOURS = 12

export const HIGHLIGHT_REST_TIME_BETWEEN_SHIFTS_IF_BIGGER_THAN_HOURS = 1
export const HIGHLIGHT_REST_TIME_BETWEEN_SHIFTS_IF_SMALLER_THAN_HOURS = 9

export const MOMENT_TIME_FORMAT = 'HH:mm'
export const MOMENT_SHIFT_DATE_FORMAT = 'dd D.M.'
export const MOMENT_SHIFTLIST_DATE_FORMAT = 'D.M.YYYY'
export const MOMENT_REST_TIME_FORMAT = 'D.M. HH:mm'

export const TASK_RETRY_COUNT = 0
export const TASK_RETRY_COOLDOWN_MS = 0

export const INTERNAL_TICKER_RATE_MS = 15000
export const PUNCTUALITY_PAGE_REFRESH_INTERVAL_MS = 60 * 1000
export const FETCH_FINDINGS_POLL_RATE_MS = 5 * 60 * 1000
export const AMENDMENT_CONFIRMATION_DURATION_MS = 4000
export const INCIDENT_POLL_RATE_MS = 3 * 60 * 1000

export const DEFAULT_NOTIFICATION = 'Muista lähtövalmius– ja lähtöpoikkeusilmoitus.'

export const TASK_TITLE_MAX_CHAR = 9
export const PHONECONTACT_LIST_CHUNK_SIZE = 10

//If more are added, remember to add translations links to fi.json
export const CALENDAR_APPS: Array<string> = ['instructions', 'googleCalendar', 'outlook']

export const EMPTY_CN_CODES: Array<string> = [
  '86090010',
  '86090090',
  '99310000',
  '99320000',
  '99330000',
  '99410000',
  '99420000',
  '99430000',
]

export const OBSERVATION_MESSAGE_TTL_HRS = 36

export const OBSERVATION_MESSAGE_CAUSES = [
  'Ei yhteyttä liikenteenohjaukseen',
  'Kalustossa ongelmia',
  'Matkustajaruuhkaa asemalla',
  'Jäätä raiteilla',
  'Henkilökunta myöhässä',
  'Toinen juna edessä',
  'Pyyntö ohjaukselta odottaa',
  'Sairastapaus',
  'Muu syy',
]

export const TEST_PUSH_MESSAGE_TTL_HRS = 36

export const POWER_BI_LINK =
  'https://app.powerbi.com/groups/4bdb3b36-fee2-4d88-87e3-6c57f63aabc4/reports/d255e478-e40e-466a-8f93-92aafb10eb50/ReportSection124a9f22d4adfac3bfd1?experience=power-bi'

export const LAHIVEKSI_LINK = 'https://vrgroup.sharepoint.com/sites/LahiVeksi'
export const VEKSI_LINK = 'https://vrgroup.sharepoint.com/sites/JunisVeksi'

export const MAJOR_INCIDENT_FEEDBACK_LINK = 'https://link.webropolsurveys.com/S/153BC23B3F62C269'

export const NEWS_CRITICALITIES = {
  CRITICAL: 'Kriittinen',
  IMPORTANT: 'Tärkeä',
  NORMAL: 'Normaali',
}

export const ASSEMBLIES_POLLING_RATE_MS: number = 5 * 60 * 1000

export const DEFAULT_DEFECT_GROUP = 'VI'

export const APP_VERSION_STORAGE_KEY = 'appVersion'

export const MAX_CAUSE_LABELS = 1

export const ACTIVE_SM_DISRUPTION_END_MESSAGE_DURATION: DurationInputArg1 = { hours: 1 }

export const LANGUAGE_ORDER = { FI: 0, SE: 1, EN: 2 } as const

export const PHONE_KULJETTAJIEN_TUKIPALVELU = '0294330050'

export const TASK_OTHER_TRAINS_PUNCTUALITY_CUTOFF_MS = 60 * 60 * 1000
export const NEXT_TASK_FINDINGS_POLLING_CUTOFF_MS = 60 * 60 * 1000
