import { Router } from 'found'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { Locomotive } from '../../types'
import {
  ContentCol,
  SectionTitle,
  StickyCol,
  Table,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from './TableComponents'

type Props = {
  color: string
  t: TFunction
  locomotives: Array<Locomotive>
  router: Router
  sourceSystem: string
  brakeRegime: string
}

type Station = 'origStation' | 'destinationStation'

// Only KAPU trains have station information
const getStationName = (locomotive: Locomotive, station: Station) => {
  if (locomotive[station] && locomotive[station]?.stationAbbreviation) {
    return locomotive[station]?.stationAbbreviation
  }
  return ''
}

const CarList = ({ t, locomotives, color, router, sourceSystem, brakeRegime }: Props) => (
  <div>
    <SectionTitle>{t('locomotives')}</SectionTitle>
    <TableContainer stickyCol>
      <Table width="600px">
        <tbody>
          <TableHeaderRow color={color}>
            <StickyCol colSpan={2} color={color} left="0" width="150px">
              {t('locomotiveNumber')}
            </StickyCol>
            <TableHeader>{t('brakeWeight')}</TableHeader>
            <TableHeader>{t('grossWeight')}</TableHeader>
            <TableHeader>{t('locTypeCode')}</TableHeader>
            <TableHeader>{t('startStation')}</TableHeader>
            <TableHeader>{t('destinationStation')}</TableHeader>
          </TableHeaderRow>
          {locomotives.map((locomotive) => {
            return (
              <TableRow key={'loc-' + locomotive.orderNumber}>
                <StickyCol borderRight blue left="0" width="20px">
                  {locomotive.orderNumber}
                </StickyCol>
                <StickyCol
                  borderRight
                  blue
                  onClick={() => router.push(`/restrictionInfos/${locomotive.locomotiveNumber}`)}
                  left="30px"
                  width="120px"
                >
                  {locomotive.locomotiveNumber}
                </StickyCol>
                <ContentCol>{locomotive.brakeWeight}</ContentCol>
                <ContentCol>{locomotive.weight}</ContentCol>
                <ContentCol>{locomotive.locTypeCode}</ContentCol>
                <ContentCol>{getStationName(locomotive, 'origStation')}</ContentCol>
                <ContentCol>{getStationName(locomotive, 'destinationStation')}</ContentCol>
              </TableRow>
            )
          })}
        </tbody>
      </Table>
    </TableContainer>
  </div>
)

export default withTranslation()(CarList)
