import { Box, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TFunction } from 'i18next'
import { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Network from '../../icons/Network'
import { isMaintenanceUser } from '../../lib/roleUtils'
import { homeURL } from '../../lib/url'
import { theme as oldTheme } from '../../Theme'
import { AppState, Children, CrewNotice, UserState } from '../../types'
import AdminBar from '../admin-bar/AdminBar'
import CrewNoticeNotification from '../custom-notification/CrewNoticeNotification'
import { SmDisruptionNotificationContainer } from '../custom-notification/SmDisruptionNotificationContainer'
import ErrorBoundary from '../ErrorBoundary'
import MainMenu from '../main-menu/MainMenu'
import FixedBottom from './FixedBottom'

const Constrain = styled('div')(({ theme }) => ({
  ...theme.layout.flexRow,
  ...theme.layout.fluidWidth(theme, 'tablet'),
  padding: theme.spacing('small'),
  height: '60px',
  boxSizing: 'border-box',
}))

type Props = {
  t: TFunction
  children: Children
  network?: 'ON' | 'LOW' | 'OFF'
  showableCrewNotice?: CrewNotice
  showSmDisruptionNotice?: boolean
  footer?: JSX.Element
  footerHeight?: string
  overflowVisible?: boolean
  admin?: boolean
  disableNotifications?: boolean
  user?: UserState
}

const InnerPage = ({
  t,
  children,
  network,
  showableCrewNotice,
  showSmDisruptionNotice,
  footer,
  footerHeight,
  overflowVisible,
  admin,
  disableNotifications,
}: Props) => {
  const showNetworkStatus = network === 'OFF'

  return (
    <Fragment>
      {showNetworkStatus ? (
        <Box
          color="network.color"
          bgcolor="network.background"
          sx={{
            width: '100%',
            height: '60px',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '3',
          }}
        >
          <Constrain>
            <Network iconSize="huge" />
            <Typography variant="adminBarBase">{t(`network.${network.toLowerCase()}`)}</Typography>
          </Constrain>
        </Box>
      ) : undefined}
      <Container
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.down('tablet')]: {
            maxWidth: theme.breakpoints.values.tablet,
          },
          overflow: overflowVisible ? 'visible' : 'hidden',
          paddingTop: showNetworkStatus ? '60px' : 0,
          paddingBottom: footerHeight,
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        <ErrorBoundary>
          {admin ? <AdminBar /> : undefined}
          {children}
        </ErrorBoundary>
      </Container>

      {!disableNotifications && showableCrewNotice && (
        <CrewNoticeNotification t={t} crewNotice={showableCrewNotice} dynamic />
      )}
      <FixedBottom>
        {!disableNotifications && showSmDisruptionNotice && <SmDisruptionNotificationContainer />}
        {footer}
      </FixedBottom>
    </Fragment>
  )
}

const mapStateToProps = (state: AppState): unknown => {
  const noticesById = state.crewNotices?.byCrewNoticeId
  const hasNotices = Object.keys(noticesById).length > 0
  const showableCrewNoticeId: string = hasNotices
    ? Object.keys(noticesById).find((k) => noticesById[k] && noticesById[k].ack !== 'ACKNOWLEDGED')
    : ''
  const showableCrewNotice: CrewNotice | undefined = showableCrewNoticeId
    ? noticesById[showableCrewNoticeId]
    : undefined

  const showSmDisruptionNotice =
    state.smDisruptions.lastOrigin === 'push' &&
    state.found.resolvedMatch.location.pathname !== homeURL()
  const user: UserState = state.user

  const showNotification = state.incidents.showNotification

  return {
    admin: state.user.admin || state.user.read_admin || state.user.commuter_manager,
    network: state.system.network,
    showableCrewNotice,
    showSmDisruptionNotice,
    maintenance: isMaintenanceUser(user),
    notification: showNotification,
  }
}

export const FooterPage = connect(mapStateToProps)(withTranslation()(InnerPage))

const Page = ({
  children,
  menu,
  overflowVisible = false,
  maintenance,
  notification,
}: {
  children: Children
  menu?: string
  overflowVisible?: boolean
  maintenance?: boolean
  notification?: boolean
}) => {
  return (
    <FooterPage
      footer={<MainMenu current={menu} maintenance={maintenance} notification={notification} />}
      footerHeight={oldTheme.maxHeights.footer}
      overflowVisible={overflowVisible}
    >
      {children}
    </FooterPage>
  )
}

export default connect(mapStateToProps)(Page)
