import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Fragment, useState } from 'react'
import { Trans, withTranslation } from 'react-i18next'

import Battery20 from '../../icons/energy-efficiency/Battery20'
import Battery40 from '../../icons/energy-efficiency/Battery40'
import Battery60 from '../../icons/energy-efficiency/Battery60'
import Battery80 from '../../icons/energy-efficiency/Battery80'
import Battery100 from '../../icons/energy-efficiency/Battery100'
import InfoFill from '../../icons/InfoFill'
import { roundSafe } from '../../lib/generalUtils'
import moment from '../../lib/moment-fi'
import { color, defaultTextColor, theme } from '../../Theme'
import { RowProps, TextProps } from '../../types/App'
import {
  EnergyEfficiencyComparativeData,
  EnergyEfficiencyLeg,
  EnergyEfficiencyShift,
} from '../../types/Input'
import { Column } from '../page/PageComponents'

const EnergyContainer = styled(Column)`
  border: 1px solid ${color('grayLight')};
  margin-top: 10px;
  padding: 8px;
`

const Row = styled.div<RowProps>`
  ${(p) => (p.flexDirection === 'column' ? theme.layout.flexColumn : theme.layout.flexRow)};
  flex-wrap: wrap;
  justify-content: ${(p) => p.justifyContent || 'space-between'};
  margin-top: ${(p) => p.marginTop || '0px'};
  margin-bottom: ${(p) => p.marginBottom || '0px'};
  align-items: ${(p) => p.alignItems || 'center'};
  align-content: ${(p) => p.alignContent || 'center'};
  flex: 1 1 0;
  flex-grow: ${(p) => p.flexGrow || '1'};
  min-width: ${(p) => p.minWidth || '0px'};
`

const IconContainer = styled.div`
  display: flex;
  margin-right: 40px;
`

export const InfoIconContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 10px;
`

const Text = styled.div<TextProps>`
  ${(p) => theme.text('normal', 'content', p.bold ? 'bold' : 'normal')};
  color: ${defaultTextColor};
  text-align: ${(p) => p.align || 'left'};
  margin-right: ${(p) => p.marginRight || '0px'};
`

type Props = {
  shift: EnergyEfficiencyShift
  t: TFunction
}

const formatDateHeader = (dateStr: string) => {
  if (dateStr) {
    const date = moment(dateStr)
    const weekDay = date.format('dddd').substring(0, 2)
    const dayAndMonth = date.format('DD.MM.')
    return `${weekDay} ${dayAndMonth}`
  }

  return ''
}

const hasComparativeDataValues = (comparativeData: EnergyEfficiencyComparativeData | null) =>
  comparativeData &&
  (comparativeData.pctl20 ||
    comparativeData.pctl40 ||
    comparativeData.pctl60 ||
    comparativeData.pctl80)

export const generateConsumptionIcon = (
  consumption?: number | null,
  comparativeData?: EnergyEfficiencyComparativeData | null
) => {
  if (!comparativeData || !consumption || !hasComparativeDataValues(comparativeData)) return null

  if (comparativeData.pctl20 && consumption <= comparativeData.pctl20) return <Battery100 />
  else if (comparativeData.pctl40 && consumption <= comparativeData.pctl40) return <Battery80 />
  else if (comparativeData.pctl60 && consumption <= comparativeData.pctl60) return <Battery60 />
  else if (comparativeData.pctl80 && consumption <= comparativeData.pctl80) return <Battery40 />

  return <Battery20 />
}

const hasValidSampleSize = (comparativeData?: EnergyEfficiencyComparativeData | null) =>
  comparativeData && comparativeData.n && comparativeData.n > 0

const inferConsumptionOverviewKey = (leg: EnergyEfficiencyLeg) =>
  leg.consumptionGeneral || hasValidSampleSize(leg.comparativeData)
    ? 'energyConsumptionOverview'
    : 'energyConsumptionOverviewNoData'

const EnergyEfficiencyOverview = ({ t, shift }: Props) => {
  const [showConsumptionInfo, setShowConsumptionInfo] = useState(false)

  return (
    <EnergyContainer>
      <Row marginBottom="10px">
        <Text bold>
          {t('energyOverview')} {shift.shiftId ? shift.shiftId + ' ' : ''}
          {formatDateHeader(shift.shiftDate)}
        </Text>
        <InfoIconContainer onClick={() => setShowConsumptionInfo(!showConsumptionInfo)}>
          <InfoFill iconSize="normal" />
        </InfoIconContainer>
      </Row>
      <Row alignItems="flex-start">
        <Row flexGrow={1.3}>
          <Text bold>{t('task')}</Text>
        </Row>
        <Row flexDirection="column">
          <Text bold>{t('energyConsumption')}</Text>
        </Row>
        <Row minWidth="40px" flexGrow="0" />
        <Row flexDirection="column" alignContent="flex-end">
          <Text bold align="end">
            {t('energyTop20Median')}
          </Text>
        </Row>
      </Row>
      {shift.legs &&
        shift.legs.map((leg) => (
          <Fragment key={`${shift.shiftId}-${leg.trainNumber}-${leg.depStation}-${leg.arrStation}`}>
            <Row marginTop={showConsumptionInfo ? '20px' : '0px'} marginBottom="3px">
              <Row flexGrow={1.3} justifyContent="flex-start">
                <Text bold marginRight="2px">
                  {leg.commuterLineId ? leg.commuterLineId + ' ' : ''}
                  {leg.trainNumber}
                </Text>
                <Text>{`${leg.depStation || ''}-${leg.arrStation || ''}`}</Text>
              </Row>
              <Row justifyContent="center">
                <Text bold>
                  {roundSafe(leg.consumptionGeneral) || '-'} {leg.consumptionGeneralUnit}
                </Text>
              </Row>
              <Row minWidth="40px" flexGrow="0" justifyContent="center">
                <IconContainer>
                  {generateConsumptionIcon(leg.consumptionGeneral, leg.comparativeData)}
                </IconContainer>
              </Row>
              <Row justifyContent="flex-end">
                <Text marginRight="8px">
                  {roundSafe(leg.comparativeData?.pctl20) || '-'} /{' '}
                  {roundSafe(leg.comparativeData?.median) || '-'}
                </Text>
              </Row>
            </Row>
            {showConsumptionInfo && (
              <Row>
                <Text>
                  <Trans
                    i18nKey={inferConsumptionOverviewKey(leg)}
                    default=""
                    values={{
                      sampleSize: leg.comparativeData?.n || '-',
                      method: leg.comparativeData?.method || '-',
                    }}
                    components={{ bold: <strong />, break: <br /> }}
                  />
                </Text>
              </Row>
            )}
          </Fragment>
        ))}
      {showConsumptionInfo && (
        <Row marginTop="50px">
          <Text>
            <Trans
              i18nKey="energyConsumptionOverviewDescription"
              default=""
              components={{ bold: <strong />, break: <br /> }}
            />
          </Text>
        </Row>
      )}
    </EnergyContainer>
  )
}

export default withTranslation()(EnergyEfficiencyOverview)
