import styled from '@emotion/styled'
import { Alert } from '@mui/material'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { useTypedDispatch } from '..'
import { fetchSchedule } from '../actions/api'
import { fetchIncidentsAction, fetchIncidentsEnabledAction } from '../actions/incidents'
import { changeMainDay, toggleMainHeader } from '../actions/mainpage'
import { dismissPushAlert } from '../actions/system'
import ActionError from '../components/ActionError'
import AnnotatedDuration from '../components/annotated/AnnotatedDuration'
import AnnotatedTime from '../components/annotated/AnnotatedTime'
import { SmDisruptionNotificationContainer } from '../components/custom-notification/SmDisruptionNotificationContainer'
import DayOverview from '../components/DayOverview'
import EnergyEfficiencyOverview from '../components/energy-efficiency/EnergyEfficiencyOverview'
import IncidentsList from '../components/incidents/IncidentsList'
import { LiitoIncident } from '../components/incidents/types'
import Link from '../components/Link'
import LoadingIndicator from '../components/LoadingIndicator'
import NewsFeed from '../components/news-feed/NewsFeed'
import NextShift from '../components/NextShift'
import Page from '../components/page/Page'
import { Column, Container, Content } from '../components/page/PageComponents'
import Separator from '../components/Separator'
import ShiftSignIn from '../components/shift/ShiftSignIn'
import Timeline from '../components/timeline/Timeline'
import TimelineSection from '../components/timeline/TimelineSection'
import VerticalLayout from '../components/VerticalLayout'
import moment from '../lib/moment-fi'
import { checkUserForDriverRole } from '../lib/roleUtils'
import { shiftURL } from '../lib/url'
import {
  isMobileSelector,
  mainPageSelector,
  nowSelector,
  shiftIsPassed,
  shiftsInNextDaysSelector,
  shiftsSortedByDateSelector,
} from '../Selectors'
import { color, theme } from '../Theme'
import {
  Action,
  AppState,
  Dispatch,
  EnergyEfficiency,
  MainPageState,
  Moment,
  News,
  Shift,
  UserState,
} from '../types'
import { AlertInfo, ImageProps } from '../types/App'

const AlertContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  margin-top: ${theme.spacing.sizes.tiny};
`

const ShiftContainer = styled.div`
  ${theme.layout.flexCenterH};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  flex-direction: row;
`
const ExtraContentContainer = styled(Column)`
  border-top: 1px solid ${color('grayLight')};
  padding-top: ${theme.spacing.sizes.normal};
  margin-top: ${theme.spacing.sizes.huge};
`

const ShiftContent = styled.div`
  ${theme.layout.fluidWidthMargin(theme.maxWidths.column, theme.spacing.sizes.small)};
`

const Constrain = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.paddedColumn)};
`

const PadEnds = styled.div`
  ${theme.spacing.ends('large')};
`

const PadBoth = styled.div`
  ${theme.spacing.all('large')};
`

const Centered = styled(Link)`
  ${theme.text('smaller', 'decorative', 'bold')};
  ${theme.spacing.ends('large')};
  display: block;
  text-align: center;
`

const Text = styled.div`
  ${theme.text()};
  color: ${color('grayDark')};
  text-align: center;
`

const OverlayText = styled.div`
  ${theme.text('largest', 'decorative')};
  color: ${color('white')};
  text-align: center;
  position: relative;
  top: -160px;
  left: 10px;
  height: 0;
`

const Image = styled.img<ImageProps>`
  ${theme.spacing.all('large')};
  box-sizing: border-box;
  display: block;
  opacity: ${(p) => (p.theme.nightMode ? 0.5 : 1)};
  height: ${theme.maxHeights.shiftEndingImage};
`

const SignInContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

type Props = {
  t: TFunction
  now: Moment
  isMobile: boolean
  loading: boolean
  error: string
  user: UserState
  dayIndex: number
  totalShifts: number
  headerOpen: boolean
  nextShift?: Shift | null
  shifts: Array<Shift>
  onDayChange: (arg0: number) => void
  onShiftChange: (arg0: number) => void
  onToggleHeader: () => void
  retrySchedule: (arg0: string) => void
  dismissPushAlert: () => void
  noShifts: boolean
  energyEfficiency: EnergyEfficiency | undefined
  news: {
    loading: boolean
    error: string
    news: Array<News>
  }
  incidents: LiitoIncident[]
  incidentsEnabled: boolean
  pushStatus: AlertInfo
  isPushAlertDismissed: boolean
  router: Router
}

const MainPage = ({
  now,
  isMobile,
  noShifts,
  loading,
  error,
  user,
  shifts,
  nextShift,
  energyEfficiency,
  news,
  incidents,
  incidentsEnabled,
  pushStatus,
  isPushAlertDismissed,
  retrySchedule,
  dismissPushAlert,
  router,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  useEffect(() => {
    dispatch(fetchIncidentsEnabledAction())
  }, [dispatch, user])

  useEffect(() => {
    if (incidentsEnabled) {
      dispatch(fetchIncidentsAction())
    }
  }, [dispatch, incidentsEnabled, user])

  const driverRole = checkUserForDriverRole(user)

  return (
    <Page menu="start">
      <Container>
        <Content marginBottom="10px">
          <AlertContainer>
            {!isPushAlertDismissed && !!pushStatus.key && 'success' !== pushStatus.level && (
              <Alert variant="outlined" severity="info" onClose={() => dismissPushAlert()}>
                {t(`pushMessageAlert.${pushStatus.key}`)}
              </Alert>
            )}
          </AlertContainer>
        </Content>
      </Container>
      <ShiftContainer>
        {shifts.length > 0 ? <Timeline /> : undefined}
        <ShiftContent>
          {error
            ? !user.commuter_driver &&
              !user.commuter_conductor && (
                <ActionError
                  error={error}
                  action={() => retrySchedule(user.number)}
                  actionText="retry"
                />
              )
            : undefined}
          {loading ? <LoadingIndicator size="normal" padded={true} /> : undefined}
          {noShifts ? (
            <Constrain>
              <PadBoth>
                <Text>{t('scheduleLooksLike')}</Text>
                <Image src="/illustrations/europe-green.png" />
                <OverlayText>{t('containsResting')}</OverlayText>
                <Text>{t('rememberToRelax')}</Text>
              </PadBoth>
            </Constrain>
          ) : undefined}
          {shifts.map((shift, index) => {
            const past = shiftIsPassed(now, shift)

            return (
              <div key={shift.id}>
                <TimelineSection isMobile={isMobile} type="main">
                  <Constrain>
                    <DayOverview index={index} shift={shift} />
                    <VerticalLayout>
                      <PadEnds>
                        <AnnotatedTime
                          now={now}
                          horizontal={true}
                          title={t('startDateTime')}
                          timestamp={shift.scheduleStartTime || shift.startDateTime}
                          duration={shift.schedulePreparation || shift.preparation}
                        />
                      </PadEnds>
                      <PadEnds>
                        <AnnotatedTime
                          now={now}
                          horizontal={true}
                          title={t('endTime')}
                          timestamp={shift.scheduleEndTime || shift.endDateTime}
                          duration={shift.scheduleWrapUp || shift.wrapUp}
                        />
                      </PadEnds>
                      <PadEnds>
                        <AnnotatedDuration
                          horizontal={true}
                          title={t('shiftDuration')}
                          duration={
                            shift.isCommuter
                              ? shift.duration
                              : shift.partDuration ||
                                moment(shift.endDateTime).diff(shift.startDateTime)
                          }
                        />
                      </PadEnds>
                    </VerticalLayout>
                  </Constrain>
                </TimelineSection>
                {!past && !shift.isCommuter ? (
                  <SignInContainer>
                    <ShiftSignIn isMobile={true} shift={shift} />
                  </SignInContainer>
                ) : undefined}
                <Centered to={shift ? shiftURL(shift.id) : '/'}>
                  {t('seeShiftInstructions')}
                </Centered>
              </div>
            )
          })}
        </ShiftContent>
      </ShiftContainer>
      {noShifts && nextShift ? (
        <PadBoth>
          <Separator />
          <NextShift now={now} shift={nextShift} router={router} />
        </PadBoth>
      ) : undefined}
      <Container>
        <Content marginBottom="10px">
          <ExtraContentContainer>
            <SmDisruptionNotificationContainer
              fetchHistory={driverRole === 'commuter_driver'}
              closable={false}
            />
            {driverRole && <NewsFeed news={news} role={driverRole} />}
            {energyEfficiency &&
              energyEfficiency.shifts &&
              energyEfficiency.shifts.map((shift) => (
                <EnergyEfficiencyOverview
                  shift={shift}
                  key={`energy-overview-${shift.shiftDate}-${shift.shiftId}`}
                />
              ))}
            {incidentsEnabled && <IncidentsList incidents={incidents} />}
          </ExtraContentContainer>
        </Content>
      </Container>
    </Page>
  )
}

MainPage.displayName = 'MainPage'

const mapStateToProps = (state: AppState) => {
  const user = state.user
  const { dayIndex, headerOpen } = mainPageSelector(state) as MainPageState
  const now = nowSelector(state)

  const shiftsNearBy = shiftsInNextDaysSelector(state)
  const shifts = shiftsNearBy[dayIndex]
  const isMobile = isMobileSelector(state) as boolean
  const loading = state.shifts.loading
  const error = state.shifts.error
  const energyEfficiency = state.energyEfficiency.energyEfficiency

  const endOfDate = now.clone().endOf('day')
  const nextShift = shiftsSortedByDateSelector(state).find((s) =>
    moment(s.startDateTime).isAfter(endOfDate)
  )

  const noShifts = shifts.length === 0 && !loading && !error

  const news = state.news

  const incidents = state.incidents.incidents
  const incidentsEnabled = state.incidents.incidentsEnabled

  const pushStatus = state.system.pushStatus
  const isPushAlertDismissed = state.system.pushAlertDismissed

  return {
    now,
    isMobile,
    user,
    shifts,
    nextShift,
    dayIndex,
    headerOpen,
    loading,
    error,
    noShifts,
    energyEfficiency,
    news,
    incidents,
    incidentsEnabled,
    pushStatus,
    isPushAlertDismissed,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDayChange: (index: any) => dispatch(changeMainDay(index)),
  onToggleHeader: () => dispatch(toggleMainHeader()),
  retrySchedule: (userNumber: string) => dispatch(fetchSchedule(userNumber) as unknown as Action),
  dismissPushAlert: () => dispatch(dismissPushAlert() as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
