import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import React from 'react'

import { useTypedDispatch } from '../../'
import { sendCrewNoticeAck } from '../../actions/api'
import { closeMassCrewNotice } from '../../actions/crewNotice'
import { CrewNotice, CrewNoticeAck } from '../../types'
import DynamicNotification from '../notification/DynamicNotification'
import NotificationBar, { ColorScheme } from '../notification/NotificationBar'

type Props = {
  t: TFunction
  crewNotice: CrewNotice
  dynamic?: boolean
  margin?: string
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  marginLeft?: string
  borderTop?: boolean
}

const CrewNoticeNotification = ({
  t,
  crewNotice,
  dynamic,
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  borderTop,
}: Props) => {
  const dispatch = useTypedDispatch()

  const loading = crewNotice?.loading || false
  const error = crewNotice?.error || ''
  const isMass = crewNotice.isMassNotice
  const isAcknowledged = isMass || crewNotice.ack === 'ACKNOWLEDGED'

  const backgroundColors: ColorScheme = { day: 'primaryBlueTinted', night: 'primaryBlue' }
  const foregroundColors: ColorScheme = { day: 'primaryBlue', night: 'primaryBlueTinted' }
  const colorOverrides = isMass
    ? {
        Bar: {
          background: backgroundColors,
          border: foregroundColors,
        },
        Circle: {
          color: foregroundColors,
          border: foregroundColors,
        },
        Text: { color: foregroundColors },
        CloseButtonText: { color: foregroundColors },
        BarButton: { color: foregroundColors },
        ButtonText: { color: foregroundColors },
        RightMargin: { color: foregroundColors },
        LoaderContainer: { color: foregroundColors },
        DoneText: { color: foregroundColors },
      }
    : undefined

  const currentTime = moment()
  const sendAck = (ack: CrewNoticeAck) =>
    dispatch(sendCrewNoticeAck(crewNotice.crewNoticeId, ack, currentTime))
  const onAck = isMass ? undefined : () => sendAck('ACKNOWLEDGED')
  const onClose = isMass
    ? () => {
        sendAck('ACKNOWLEDGED')
        dispatch(closeMassCrewNotice(crewNotice.crewNoticeId))
      }
    : undefined

  if (!!crewNotice && !crewNotice.ack && !loading && !isMass) sendAck('SEEN')

  return dynamic ? (
    <DynamicNotification
      text={crewNotice.content}
      sentAt={crewNotice.sentAt}
      actionText={t('acknowledge')}
      actionHandler={onAck}
      closeHandler={onClose}
      loading={loading}
      error={error}
      colorOverrides={colorOverrides}
    />
  ) : (
    <NotificationBar
      markdown={crewNotice.content}
      action={onAck}
      onClose={onClose}
      actionText={t('acknowledge')}
      loading={loading}
      error={error}
      sentAt={crewNotice.sentAt}
      isAcknowledged={isAcknowledged}
      doneText={
        crewNotice.eventAt && isAcknowledged
          ? `${t('acknowledged')} ${moment(crewNotice.eventAt).format('H:mm')}`
          : undefined
      }
      margin={margin}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      borderTop={borderTop}
      colorOverrides={colorOverrides}
    />
  )
}

export default CrewNoticeNotification
