import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { closeShiftTasks, openShiftTask, selectShiftTask } from '../../actions/shiftpage'
import moment from '../../lib/moment-fi'
import {
  contactsForCommuterTask,
  contactsForTask,
  getCauses,
  inferTaskEquipment,
  isMobileSelector,
  nightModeSelector,
  nowSelector,
  selectOperatingDateForTask,
  selectTaskInstruction,
  selectTaskTitle,
  shiftIsPassed,
  shiftSignInStatus,
  taskInfoLocalized,
  taskIsActive,
  taskIsPassed,
} from '../../Selectors'
import { getColor, LAYOUT_LEFT_EDGE, theme } from '../../Theme'
import { AppState, Color, Contact, Dispatch, Shift, Task, TaskState } from '../../types'
import { Delay, TimetableRow, TracksFromPunctuality, TrainPunctuality } from '../../types/Input'
import RouteTitleRow from '../route-title/RouteTitleRow'
import AssemblyChanges from './AssemblyChanges'
import TaskRowContent from './TaskRowContent'

interface BgWrapperProps {
  active: boolean
  past: boolean
  bottom: boolean
  even: boolean
}

interface RowProps {
  active: boolean
  even: boolean
  past: boolean
  bottom: boolean
}

const rowBgDay = ({ active, even }: { active: boolean; even: boolean }): Color => {
  if (active) return 'shallowGreen'
  if (even) return 'white'
  return 'grayBackground'
}

const rowBgNight = ({ active, even }: { active: boolean; even: boolean }): Color => {
  if (active) return 'darkGreen'
  if (even) return 'nightSeparator'
  return 'nightGray'
}

const stripeColor = ({ active, past }: { active: boolean; past: boolean }): Color => {
  if (past) return 'primaryGreenTinted'
  if (active) return 'primaryGreen'
  return 'primaryBlue'
}

const BgWrapper = styled.div<BgWrapperProps>`
  background: ${(p) => getColor(p.theme, [rowBgDay(p)], [rowBgNight(p)])};
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  border-left: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  border-right: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  border-bottom: ${(p) =>
    p.bottom ? `1px solid ${getColor(p.theme, ['grayBlue'], ['nightBlack'])}` : 'none'};
  ${(p) =>
    (p.active || p.past) &&
    `border-left: 5px solid ${getColor(p.theme, [stripeColor(p)], [stripeColor(p)])};`}
`

const Row = styled.div<RowProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.sides('tiny')};
  ${theme.spacing.ends('smallest')};
  min-height: ${theme.spacing.sizes.huge};
  flex: 1;
  ${(p) => !p.active && !p.past && `padding-left: ${LAYOUT_LEFT_EDGE - 7}px;`}
`

const Expanded = styled.div`
  position: relative;
  box-sizing: border-box;
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayLight'], ['grayDark'])};
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayLight'], ['grayDark'])};

  @media (min-width: ${theme.maxWidths.content}) {
    ${theme.effects.shadowLarge};
  }

  @media (min-width: ${theme.breakpoints.large}) {
    left: -${theme.spacing.sizes.large};
    width: calc(100% + ${theme.spacing.sizes.huge});
  }
`

const div = ({ children }: { children: ReactNode }) => <div>{children}</div>

/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {}

const isEstimate = (t: TimetableRow): boolean => {
  return !!t.liveEstimateTime && !t.actualTime && moment(t.liveEstimateTime).isAfter(moment())
}

const getDelayMinutes = (from: string, to: string, punctuality: TrainPunctuality): number => {
  const start = punctuality.timeTableRows
    .filter((t) => t.type === 'DEPARTURE')
    .find((t) => t.stationShortCode === from)

  const end = punctuality.timeTableRows
    .filter((t) => t.type === 'ARRIVAL')
    .find((t) => t.stationShortCode === to)

  if (start && isEstimate(start)) return start.differenceInMinutes
  if (end && !isEstimate(end)) return end.differenceInMinutes

  const last =
    punctuality.timeTableRows.length > 0 &&
    punctuality.timeTableRows.filter((t) => !isEstimate(t)).reverse()[0]

  return (last && last.differenceInMinutes) || 0
}

const collectTaskPunctuality = (
  from: string,
  to: string,
  punctuality: TrainPunctuality
): Array<Delay> => {
  const taskStops: TimetableRow[] = []
  let betweenFromAndTo = false
  punctuality.timeTableRows.forEach((t) => {
    if (betweenFromAndTo)
      // iterating the leg of the task
      taskStops.push(t)

    if (!betweenFromAndTo && t.stationShortCode === from) {
      // just found start point
      betweenFromAndTo = true
      taskStops.push(t)
    }

    if (betweenFromAndTo && t.stationShortCode === to)
      // just found end point
      betweenFromAndTo = false
  })

  const delays = taskStops.map((t) => ({
    causes: t.causes,
    differenceInMinutes: t.differenceInMinutes,
  }))

  return delays
}

const getPunctualityTracks = (
  from: string,
  to: string,
  punctuality: TrainPunctuality
): TracksFromPunctuality => {
  const start = punctuality.timeTableRows.find(
    (t) => t.stationShortCode === from && t.type === 'DEPARTURE'
  )
  const departure = punctuality.timeTableRows.find(
    (t) => t.stationShortCode === to && t.type === 'ARRIVAL'
  )
  const departureTrack = start ? start.commercialTrack : ''
  const arrivalTrack = departure ? departure.commercialTrack : ''
  return { departureTrack: departureTrack, arrivalTrack: arrivalTrack }
}

type Props = {
  t: TFunction
  even: boolean
  past: boolean
  active: boolean
  selected: boolean
  canSelect: boolean
  showAssemblyChanges: boolean
  bottomBorder: boolean
  shift: Shift
  task: Task
  info: Array<{
    title: string
    value: string
  }>
  contacts: Array<Contact>
  showInfo: boolean
  open: boolean
  delays: Array<Delay>
  totalCauses: number
  delayMinutes: number
  trainCategory: string
  openRow: () => void
  closeRow: () => void
  selectTask: () => void
  deselectTask: () => void
  router: Router
  commaSeparatedLocs: string
  filteredLocs: Array<string>
  nightMode: boolean
  compactView: boolean
  taskTitle: string
  isMobile: boolean
  isCommuterMealBreak: boolean
  isUnpaidMealBreak: boolean
  tracksFromPunctuality: Partial<TracksFromPunctuality>
  debugEnabled: boolean
  over48hAway: boolean
  taskState: TaskState
  nextTask: Task | null
}

class TaskRow extends Component<Props> {
  render() {
    const {
      t,
      shift,
      task,
      info,
      contacts,
      showInfo,
      even,
      past,
      delays,
      totalCauses,
      delayMinutes,
      trainCategory,
      active,
      open,
      openRow,
      closeRow,
      bottomBorder,
      router,
      commaSeparatedLocs,
      filteredLocs,
      nightMode,
      compactView,
      taskTitle,
      isMobile,
      isCommuterMealBreak,
      isUnpaidMealBreak,
      tracksFromPunctuality,
      showAssemblyChanges,
      debugEnabled,
    }: Props = this.props

    const Wrapper = !open ? div : Expanded

    const rowAction = () => {
      if (showInfo) {
        if (!open) {
          return openRow()
        }
        return closeRow()
      }
      return noop
    }

    const taskInstruction = selectTaskInstruction(
      task.trainNumber,
      task.trainNumberNumeric,
      task.instruction
    )

    return (
      <Wrapper>
        <BgWrapper
          active={active}
          even={even}
          title={task.taskName}
          past={past}
          onClick={rowAction}
          bottom={task.taskName === 'MB'}
        >
          <Row
            active={active}
            even={even}
            title={task.taskName}
            past={past}
            onClick={rowAction}
            bottom={task.taskName === 'MB'}
          >
            <RouteTitleRow
              t={t}
              task={task}
              taskTitle={taskTitle}
              isCommuter={shift.isCommuter}
              delays={delays}
              delayMinutes={delayMinutes}
              trainCategory={trainCategory}
              nightMode={nightMode}
              compactView={compactView}
              active={active}
              totalCauses={totalCauses}
              past={past}
              isCommuterMealBreak={isCommuterMealBreak}
              isUnpaidMealBreak={isUnpaidMealBreak}
              tracksFromPunctuality={tracksFromPunctuality}
              open={open}
              isMobile={isMobile}
            />
          </Row>
          {showAssemblyChanges && (
            <AssemblyChanges task={task} open={open} active={active || past} />
          )}
        </BgWrapper>
        <TaskRowContent
          shift={shift}
          active={active}
          task={task}
          taskInstruction={taskInstruction}
          contacts={contacts}
          info={info}
          router={router}
          filteredLocs={filteredLocs}
          commaSeparatedLocs={commaSeparatedLocs}
          taskTitle={taskTitle}
          nightMode={nightMode}
          delays={delays}
          delayMinutes={delayMinutes}
          even={even}
          past={past}
          bottomBorder={bottomBorder}
          isMobile={isMobile}
          totalCauses={totalCauses}
          open={open}
          isCommuterMealBreak={isCommuterMealBreak}
          rowAction={rowAction}
          debugEnabled={debugEnabled}
          t={t}
        />
      </Wrapper>
    )
  }
}

type PropsIn = {
  t: TFunction
  over48hAway: boolean
  shift: Shift
  task: Task
  taskState: TaskState
  nextTask: Task | null
}

const mapStateToProps = (
  state: AppState,
  { t, over48hAway, shift, task, taskState, nextTask }: PropsIn
) => {
  const now = nowSelector(state)
  const selected = taskState ? taskState.selected : false
  const shiftPast = shiftIsPassed(now, shift)
  const past = taskIsPassed(now, task)
  const active = !past && taskIsActive(now, task)
  const open = taskState && taskState.open
  const compactView = over48hAway && !open
  const bottomBorder = !nextTask || !!nextTask.independentNotices || !!nextTask.leadingNotices
  const signInStatus = shiftSignInStatus(state)(shift.id)
  const canSelect =
    (state.user.admin ||
      state.user.commuter_driver ||
      state.user.commuter_manager ||
      state.user.driver ||
      state.user.logistics_driver ||
      state.user.conductor ||
      state.user.other) &&
    (signInStatus.state === 'signed-in' || shiftPast)
  const showAssemblyChanges =
    state.user.admin || state.user.read_admin || state.user.conductor || state.user.waiter

  const contacts = shift.isCommuter
    ? contactsForCommuterTask(task)
    : contactsForTask(task, state.user.number)
  const info = taskInfoLocalized(t, task).filter((info) => !!info.value)
  const nightMode = nightModeSelector(state)

  const punctualities = state.punctuality.punctualityByTrain
  const operatingDate = selectOperatingDateForTask(task)
  const punctuality =
    task.trainNumberNumeric && punctualities[`${task.trainNumberNumeric}+${operatingDate}`]
  const delays = punctuality
    ? collectTaskPunctuality(task.fromStation, task.toStation, punctuality)
    : []
  const tracksFromPunctuality = punctuality
    ? getPunctualityTracks(task.fromStation, task.toStation, punctuality)
    : {}
  const totalCauses = getCauses(delays).length
  //for ongoing train, show last confirmed time, or when train is supposed to arrive at task start station
  const delayMinutes = punctuality
    ? getDelayMinutes(task.fromStation, task.toStation, punctuality)
    : 0
  const trainCategory = punctuality ? punctuality.trainCategory : ''

  const filteredLocs = inferTaskEquipment(task)
  const commaSeparatedLocs = filteredLocs.join(',')

  const taskTitle = selectTaskTitle(
    task.taskName,
    task.trainNumber,
    task.trainNumberNumeric,
    task.trainCategory,
    true
  )
  const isMobile = isMobileSelector(state)
  const isCommuterMealBreak = task.taskName === 'MB'
  const isUnpaidMealBreak = task.taskName.toUpperCase().includes('UNPBRK')

  const debugEnabled = state.system.taskRowDebugEnabled

  return {
    now,
    active,
    selected,
    canSelect,
    showAssemblyChanges,
    past,
    delays,
    totalCauses,
    delayMinutes,
    trainCategory,
    bottomBorder,
    info,
    contacts,
    open,
    compactView,
    showInfo: !!task.contacts,
    commaSeparatedLocs,
    filteredLocs,
    nightMode,
    taskTitle,
    isMobile,
    isCommuterMealBreak,
    isUnpaidMealBreak,
    tracksFromPunctuality,
    debugEnabled,
  }
}
const mapDispatchToProps = (
  dispatch: Dispatch,
  {
    shift,
    task,
  }: {
    shift: Shift
    task: Task
  }
) => ({
  openRow: (/*ev*/) => dispatch(openShiftTask(shift.id, task.id)),
  closeRow: (/*ev*/) => dispatch(closeShiftTasks(shift.id)),
  selectTask: () => dispatch(selectShiftTask(shift.id, task.id, true)),
  deselectTask: () => dispatch(selectShiftTask(shift.id, task.id, false)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskRow))
