import styled from '@emotion/styled'
import React from 'react'

import Compositions from '../../icons/Compositions'
import Defect from '../../icons/Defect'
import ObservationMessage from '../../icons/ObservationMessage'
import Personnel from '../../icons/Personnel'
import Refresh from '../../icons/Refresh'
import Stopwatch from '../../icons/Stopwatch'
import Train2 from '../../icons/Train2'
import { chooseButtonColor } from '../../lib/findings'
import { getColor, theme } from '../../Theme'
import { TimetableParams } from '../../types/Input'
import LoadingIndicator from '../LoadingIndicator'
import TimetableButton from '../timetable/TimetableButton'
import TaskButton from './TaskButton'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 34px;
  ${theme.spacing.bottom('tiny')};
  grid-gap: 4px;
`

const getPersonnelButtonColor = (
  hasContacts: boolean,
  nightMode: boolean,
  contactsOpen?: boolean
) => {
  if (!hasContacts) return getColor({ nightMode }, ['grayDark'], ['grayDark'])
  if (contactsOpen) return getColor({ nightMode }, ['primaryYellow'], ['primaryYellow'])
  return getColor({ nightMode }, ['primaryBlue'], ['primaryBlue'])
}

type Props = {
  fetchFindings: () => void
  findingsError: string
  findingsEnabled: boolean
  compositionsEnabled: boolean
  punctualityEnabled: boolean
  criticality: string | null
  loading: boolean
  openCreateDefect: (equipments?: string[]) => void
  openFindings: () => void
  openCompositions: () => void
  timetableParams: TimetableParams
  hasContacts: boolean
  contactsOpen?: boolean
  toggleContacts: () => void
  openPunctuality: () => void
  nightMode: boolean
  toggleReport: () => void
  isCommuter: boolean
  filteredEquipments: Array<string>
  showTaskReport: boolean
  showFindings: boolean
}

const TaskButtonRow = ({
  fetchFindings,
  findingsError,
  findingsEnabled,
  compositionsEnabled,
  punctualityEnabled,
  criticality,
  loading,
  hasContacts,
  openCreateDefect,
  openFindings,
  openCompositions,
  timetableParams,
  contactsOpen,
  toggleContacts,
  openPunctuality,
  nightMode,
  toggleReport,
  isCommuter,
  filteredEquipments,
  showTaskReport,
  showFindings,
}: Props) => (
  <Row>
    {showTaskReport && (
      <TaskButton onClick={toggleReport} isCommuter={isCommuter}>
        <ObservationMessage iconSize="small" />
      </TaskButton>
    )}
    {showFindings &&
      (findingsError.length > 0 ? (
        <TaskButton
          background={chooseButtonColor(criticality, nightMode, loading)}
          /* eslint-disable @typescript-eslint/no-empty-function */
          onClick={!loading ? fetchFindings : () => {}}
        >
          {loading ? (
            <LoadingIndicator size="small" padded={false} />
          ) : (
            <Refresh iconSize="smallest" />
          )}
        </TaskButton>
      ) : (
        <TaskButton
          background={chooseButtonColor(criticality, nightMode, loading)}
          disabled={!findingsEnabled}
          noClick={criticality === null || !findingsEnabled}
          /* eslint-disable @typescript-eslint/no-empty-function */
          onClick={findingsEnabled && criticality ? openFindings : () => {}}
        >
          {loading ? (
            <LoadingIndicator size="small" padded={false} />
          ) : (
            <Train2 iconSize="smallest" />
          )}
        </TaskButton>
      ))}
    {!isCommuter && (
      <TaskButton
        disabled={!compositionsEnabled}
        noClick={!compositionsEnabled}
        onClick={
          compositionsEnabled
            ? /* eslint-disable @typescript-eslint/no-empty-function */
              openCompositions
            : () => {}
        }
      >
        <Compositions iconSize="small" width="44" />
      </TaskButton>
    )}
    {filteredEquipments?.length > 0 && (
      <TaskButton onClick={() => openCreateDefect(filteredEquipments)}>
        <Defect iconSize="small" />
      </TaskButton>
    )}
    <TaskButton
      disabled={!punctualityEnabled}
      isCommuter={isCommuter}
      noClick={!punctualityEnabled}
      onClick={openPunctuality}
    >
      <Stopwatch iconSize="small" />
    </TaskButton>
    <TimetableButton isCommuter={isCommuter} timetableParams={timetableParams} />
    <TaskButton
      background={getPersonnelButtonColor(hasContacts, nightMode, contactsOpen)}
      isCommuter={isCommuter}
      onClick={toggleContacts}
      noClick={!hasContacts}
    >
      <Personnel iconSize="small" />
    </TaskButton>
  </Row>
)

export default TaskButtonRow
