import styled from '@emotion/styled'
import { Router } from 'found'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchCalendarURL, fetchSchedule, refetchSchedule } from '../actions/api'
import ActionError from '../components/ActionError'
import CalendarButton from '../components/calendar/CalendarButton'
import CalendarFreeDay from '../components/calendar/CalendarFreeDay'
import CalendarRest from '../components/calendar/CalendarRest'
import CalendarRestDay from '../components/calendar/CalendarRestDay'
import CalendarShift from '../components/calendar/CalendarShift'
import CalendarShiftList from '../components/calendar/CalendarShiftList'
import LoadingIndicator from '../components/LoadingIndicator'
import NextMonth from '../components/NextMonth'
import NextWeek from '../components/NextWeek'
import Page from '../components/page/Page'
import ScheduleHeader from '../components/schedule/ScheduleHeader'
import ScheduleLine from '../components/schedule/ScheduleLine'
import { isMobileSelector, nextShiftSelector, schedulePartsGroupedSelector } from '../Selectors'
import { theme } from '../Theme'
import { Action, AppState, Dispatch, I18n, Session, UserState } from '../types'
import { ScheduleDay, ScheduleShiftList } from '../types/App'

const Container = styled.div`
  ${theme.spacing.bottom('small')};
  height: auto;
`
const CalendarButtonContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
`

type Props = {
  t: I18n
  loading: boolean
  error: string
  shiftLists: ScheduleShiftList[]
  user: UserState
  onReload: (session: UserState) => void
  fetchCalendar: () => void
  router: Router
  scheduleFetchTime?: Date | null
}

const Day = ({ day }: { day: ScheduleDay }) => (
  <>
    {day.scheduleParts.map((part) => {
      switch (part.type) {
        case 'line':
          return <ScheduleLine key={'line'} />
        case 'shift':
          return (
            <CalendarShift
              shift={part.shift}
              hideDate={
                day.scheduleParts.find((p) => p.type === 'shift' || p.type === 'restday') !== part
              }
              key={part.shift.startDateTime}
            />
          )
        case 'rest':
          return (
            <CalendarRest
              startDateTime={part.startDateTime}
              endDateTime={part.endDateTime}
              location={part.location}
              key={part.startDateTime}
            />
          )
        case 'restday':
          return (
            <CalendarRestDay
              startDateTime={part.startDateTime}
              dayType={part.dayType}
              hideDate={
                day.scheduleParts.find((p) => p.type === 'shift' || p.type === 'restday') !== part
              }
              key={part.startDateTime}
            />
          )
        default:
          return <></>
      }
    })}
    {day.scheduleParts.every((part) => part.type === 'line') && (
      <CalendarFreeDay startDateTime={day.startDateTime} />
    )}
  </>
)

const ShiftList = ({ shiftList }: { shiftList: ScheduleShiftList }) => (
  <>
    <CalendarShiftList
      listId={shiftList.listId}
      listStartDate={shiftList.listStartDate}
      listEndDate={shiftList.listEndDate}
    />
    {shiftList.schedule.map((item) => {
      switch (item.type) {
        case 'month':
          return <NextMonth startsAt={item.startDateTime} key={'m' + item.startDateTime} />
        case 'week':
          return <NextWeek startsAt={item.startDateTime} key={'w' + item.startDateTime} />
        case 'line':
          return <ScheduleLine key={'line'} />
        case 'day':
          return <Day day={item} key={'d' + item.startDateTime} />

        default:
          return <></>
      }
    })}
  </>
)

const SchedulePage = ({
  t,
  loading,
  error,
  user,
  shiftLists,
  router,
  scheduleFetchTime,
  onReload,
}: Props) => {
  const scheduleIntervalMs = 1000 * 60 * 5

  useEffect(() => {
    fetchSchedule(user.number)
    const fetchScheduleInterval = setInterval(() => fetchSchedule(user.number), scheduleIntervalMs)
    return () => clearInterval(fetchScheduleInterval)
  }, [user.number, scheduleIntervalMs])

  const actionErrorProps = {
    t,
    error,
    action: () => onReload(user),
  }

  const formatScheduleFetchTime = (scheduleFetchTime: Date) =>
    scheduleFetchTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
    })

  return (
    <Page menu="schedule">
      <ScheduleHeader loading={loading} onReload={() => (loading ? null : onReload(user))} />
      <Container>
        {error ? (
          !user.commuter_driver && !user.commuter_conductor && scheduleFetchTime ? (
            <ActionError
              {...actionErrorProps}
              tProps={{ time: formatScheduleFetchTime(scheduleFetchTime) }}
              actionText="errors.schedule.lastFetched"
            />
          ) : (
            <ActionError {...actionErrorProps} actionText="retry" />
          )
        ) : undefined}
        {shiftLists.length === 0 && !error ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : undefined}
        {shiftLists.map((shiftList) => (
          <ShiftList shiftList={shiftList} key={shiftList.listStartDate} />
        ))}

        {shiftLists.length > 0 && !error ? (
          <CalendarButtonContainer>
            <CalendarButton optionsPageRedirect={() => router.push('/options')} />
          </CalendarButtonContainer>
        ) : undefined}
      </Container>
    </Page>
  )
}

SchedulePage.displayName = 'SchedulePage'

const mapStateToProps = (state: AppState) => {
  const isMobile = isMobileSelector(state) as boolean
  const nextShift = nextShiftSelector(state)
  const shiftLists = schedulePartsGroupedSelector(state)
  const scheduleFetchTime = state.shifts.scheduleFetchTime

  const user = state.user

  const loading = state.shifts.loading
  const error = state.shifts.error

  return {
    isMobile,
    loading,
    error,
    user,
    nextShift,
    shiftLists,
    scheduleFetchTime,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onReload: (user: Session) => dispatch(refetchSchedule(user) as unknown as Action),
  fetchCalendar: () => dispatch(fetchCalendarURL() as unknown as Action),
  fetchSchedule: (number: string) => dispatch(fetchSchedule(number) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage)
