import styled from '@emotion/styled'
import React, { useState } from 'react'

import { theme } from '../../Theme'
import { Timestamp } from '../../types'
import NotificationBar, { NotificationBarColorOverrides } from './NotificationBar'

const PositionedBar = styled.div`
  position: fixed;
  bottom: ${theme.maxHeights.footer};
  width: 100%;
`

const OpenAnimated = styled(PositionedBar)`
  animation: ${theme.animations.slideUp} 0.2s ease-in-out;
`

const CloseAnimated = styled(PositionedBar)`
  animation: ${theme.animations.slideUp} 0.2s ease-in-out reverse;
  animation-fill-mode: forwards;
`

type Props = {
  text: string
  actionText: string
  sentAt: Timestamp
  closeHandler?: () => void
  actionHandler?: () => void
  loading?: boolean
  error?: unknown
  colorOverrides?: Partial<NotificationBarColorOverrides>
}

const DynamicNotification = ({
  text,
  actionText,
  sentAt,
  closeHandler,
  actionHandler,
  loading,
  error,
  colorOverrides,
}: Props) => {
  const [close, setClose] = useState(false)

  const Animated = !close ? OpenAnimated : CloseAnimated

  const closeNotification = () => {
    console.log('close') /*eslint-disable-line */
    setClose(true)
    if (closeHandler) closeHandler()
  }

  return (
    <Animated>
      <NotificationBar
        markdown={text}
        margin={'0'}
        shadowed
        onClose={closeNotification}
        action={actionHandler}
        actionText={actionText}
        loading={loading}
        error={error}
        sentAt={sentAt}
        colorOverrides={colorOverrides}
      />
    </Animated>
  )
}

export default DynamicNotification
