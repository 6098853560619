import { isEqual } from 'lodash'

import {
  BATCH_UPDATE_CREW_NOTICE,
  CLOSE_MASS_CREW_NOTICE,
  CREW_NOTICE_ERROR,
  CREW_NOTICE_ERROR_ALL,
  START_FETCHING_ALL_CREW_NOTICES,
  START_FETCHING_CREW_NOTICE,
  UPDATE_CREW_NOTICE,
  UPDATE_CREW_NOTICE_ACK,
} from '../actions/crewNotice'
import { CrewNoticeAction } from '../actions/crewNotice'
import { CrewNoticeState } from '../types'

export const initialState = {
  byCrewNoticeId: {},
}

const crewNoticeReducer = (
  state: CrewNoticeState = initialState,
  action: CrewNoticeAction
): CrewNoticeState => {
  switch (action.type) {
    case START_FETCHING_CREW_NOTICE: {
      const newState = state.byCrewNoticeId
      if (newState[action.payload]) {
        newState[action.payload] = { ...newState[action.payload], loading: true, error: '' }
      } else {
        newState[action.payload] = {
          personnelNumber: '',
          trackNumbers: [],
          occursAt: '',
          sentAt: '',
          id: '',
          type: '',
          trains: [],
          content: '',
          crewNoticeId: action.payload,
          vehicleIds: [],
          eventAt: '',
          loading: true,
          error: '',
        }
      }

      return {
        byCrewNoticeId: { ...newState },
      }
    }

    case START_FETCHING_ALL_CREW_NOTICES: {
      const newState = { ...state.byCrewNoticeId }
      Object.keys(newState).forEach((id) => {
        newState[id] = { ...newState[id], loading: true, error: '' }
      })

      return {
        byCrewNoticeId: { ...newState },
      }
    }

    case BATCH_UPDATE_CREW_NOTICE: {
      const notices = state.byCrewNoticeId
      action.payload.forEach(
        (cn) =>
          (!notices[cn.crewNoticeId] || !isEqual(notices[cn.crewNoticeId], cn)) &&
          (notices[cn.crewNoticeId] = cn)
      )
      return {
        byCrewNoticeId: { ...notices },
      }
    }

    case UPDATE_CREW_NOTICE: {
      const noticeId = action.payload.crewNoticeId
      return {
        byCrewNoticeId:
          state.byCrewNoticeId[noticeId] && isEqual(state.byCrewNoticeId[noticeId], action.payload)
            ? state.byCrewNoticeId
            : { ...state.byCrewNoticeId, [noticeId]: action.payload },
      }
    }

    case UPDATE_CREW_NOTICE_ACK: {
      const noticeId = action.payload.crewNoticeId
      const notices = state.byCrewNoticeId
      if (notices[noticeId]) {
        notices[noticeId].ack = action.payload.ack
        notices[noticeId].eventAt = action.payload.eventAt
        notices[noticeId].loading = false
        notices[noticeId].error = ''
      }
      return {
        byCrewNoticeId: { ...notices },
      }
    }

    case CREW_NOTICE_ERROR: {
      const noticeId = action.payload.crewNoticeId
      const notices = state.byCrewNoticeId
      if (notices[noticeId]) {
        notices[noticeId].error = action.payload.error
        notices[noticeId].loading = false
      }

      return {
        byCrewNoticeId: { ...notices },
      }
    }

    case CREW_NOTICE_ERROR_ALL: {
      const newState = { ...state.byCrewNoticeId }
      Object.keys(newState).forEach((id) => {
        newState[id] = { ...newState[id], loading: false, error: action.payload }
      })

      return {
        byCrewNoticeId: { ...newState },
      }
    }

    case CLOSE_MASS_CREW_NOTICE: {
      const noticeId = action.payload
      const notices = state.byCrewNoticeId
      if (notices[noticeId]) {
        notices[noticeId].ack = 'ACKNOWLEDGED'
      }

      return {
        byCrewNoticeId: { ...notices },
      }
    }

    default:
      return state
  }
}

export default crewNoticeReducer
